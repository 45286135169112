

.job-wrap {
	padding: 50px 0 50px 0;
}
.nopaddingjob {
  padding-top: 0 !important;
}
.list-job-wrapp {
  width: 100%;
  padding-bottom: 60px;
}

.list-job-wrapp:after {
  content: "";
  clear: both;
  display: block;
}

.list-job-wrapp .title {
  width: 100%;
  background: #fff;
  border: 1px #c6c6c6 solid;
  padding: 37px 0;
  clear: both;
  overflow: hidden;
}

.list-job-wrapp .title p {
  display: block;
  float: left;
  width: 16%;
  font-family: 'playfair_displaybold';
  font-size: 18px;
  color: #4b4b4b;
  text-transform: uppercase;
  letter-spacing: 2px;
  
  font-size: 24px;

  &:first-child {
  	width: 27%;
    padding-left: 50px;
  } 
  &:last-child {
    width: 25%;
    padding-left: 30px;
  } 
}

.list-job-wrapp .list-job {
  width: 100%;
  display: block;
}

.list-job-wrapp .list-job li {
  width: 100%;
  display: block;
  clear: both;
  overflow: hidden;
  border-bottom: 1px #ebebeb solid;
  padding: 25px 0;
  background: #eff2fa;
  position: relative;

  &:hover {
  	background: #cacfdd;

  	// a,p {
  	// 	color: #fff;
  	// }
  }

  &:nth-child(2n) {
    background: #fff;

    &:hover {
    background: #cacfdd;

    // a,p {
    //   color: #fff;
    //   }
    }
  }
}

.list-job-wrapp .list-job li .tt {
  display: inline-block;
  width: 27%;
  vertical-align: middle;
  padding-left: 50px;
  font-size: 16px;
  color: #253a71;
  float: left;
  padding-right: 10px;
}

.click-jobdt {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}


// .list-job-wrapp .list-job li a:hover {
//   color: #000;
//   text-decoration: none;
// }

.list-job-wrapp .list-job li p {
  display: inline-block;
  width: 16%;
  vertical-align: middle;
  font-size: 16px;
  color: #565656;
  float: left;
  padding-right: 10px;

  span {
    display: none;
  }

  &.date {
    width: 25%;
    padding-left: 30px;
  } 
}


.job-detail {
  width: 100%;
  padding-bottom: 50px;
}

.job-detail h2 {
  text-align: left;
  padding: 48px 0;
  font-size: 48px;
  background: none;
}
.main-infojob {
  // border-bottom: 1px #d9d9d9 solid;
  // border-top: 1px #d9d9d9 solid;
  background: #fff;
  padding: 25px 25px 25px 50px;

  h3 {
    font-size: 30px;
    color: $blue-bg;
    padding: 10px 0;
  } 

  .img {
    float: right;
    width: 169px;
    text-align: center;

    img {
      max-width: 100%;
    }
  }
}
.job-detail .list-info-job {
  float: left;
  width: 50%;
  border-left: 5px #eec2a3 solid;
  padding-left: 20px;
}

.job-detail .list-info-job li {
  font-size: 18px;
  padding-bottom: 5px;
}

.job-detail .list-info-job li strong {
  font-weight: normal;
  font-family: 'sfu_universbold';
}

.job-detail .copy {
  margin-top: 48px;
  padding: 35px 25px 35px 50px;
  background-color: #fff;
}

.job-detail .copy div {
  padding-bottom: 30px;
}

.job-detail .copy h3 {
  font-size: 30px;
  color: #253a71;
  padding-bottom: 12px;
  line-height: 30px;
}

.job-detail .copy p {
  padding-bottom: 11px;
}
.job-detail .copy ul {
  margin-bottom: 11px;
}
.job-detail .copy li {
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 20px;
}
.job-detail .copy p a,
.job-detail .copy li a {
  color: $blue-bg;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.job-detail .copy .btn-bg {
  
}


.talents-home-wrapp {
  padding-top: 40px;

  .view-item {
    width: 100%;
      clear: both;
      overflow: hidden;
      position: relative;
      

      .img {
        width: 50%;
        float: right;
        text-align: center;
        padding-left: 30px;

        img {
          max-width: 100%;
        }
      }
      .copy {
        width: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        padding-right: 30px;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);

        h3 {
          font-size: 24px;
          color: $blue-bg;
          line-height: 35px;
          padding-bottom: 10px;
        }
        p {
          line-height: 24px;
          display: inline-block;
          padding-bottom: 25px;
        }
      }
  }
}

.testimo-wrap {
  padding-top: 50px;
  .testimo-item {
    margin-bottom: 40px;
    opacity: 0;

    .img {
      float: left;
      width: 50%;
      padding-right: 30px;

      img {
        width: 100%;
      }
    }
    .copy {
      width: 50%;
      float: right;
      // position: absolute;
      // top: 50%;
      // right: 0;
      // text-align: left;
      // -ms-transform: translateY(-50%);
      //   -webkit-transform: translateY(-50%);
      //   transform: translateY(-50%);
      padding: 30px 20px 0 30px;

      h3 {
        font-size: 24px;
          color: $blue-bg;
          padding-bottom: 10px
      }
      p{
        font-size: 17px;
        font-style: italic;
        line-height: 1.2;
        padding-bottom: 20px
      }
      .info {
        h4 {
          font-size: 18px;
        }
        p {
          font-style: normal;
          margin-bottom: 0;
        }
      }
    }

    &:nth-child(2n) {
      .img {
        float: right;
        padding-right: 0;
        padding-left: 30px;
      }
      .copy {
        right: auto;
        left: 0;
        padding-left: 0;
        padding-right: 30px;
      }
    }
  }
  .btn-bd {
    width: auto;
    padding: 0 30px;
  }
  .btn-bd:hover svg rect {
    stroke-width: 5;
    stroke: $blue-bg;
    stroke-dasharray: 50, 450;
    stroke-dashoffset: 400;
  }
}
#pJobList .more-downtown {
  border-top: none; 
  padding-bottom: 0;

  .copy {
    text-align: left;
    padding-right: 10px;
    width: 50%;
    left: auto;
    right: 0;

    h3 {
      font-size: 40px;
      color: $blue-bg;
      line-height: 53px;
      padding-bottom: 25px;
    }
    // a {
    //   color: #fff;
    //   height: 50px;
    //   line-height: 50px;
    //   letter-spacing: 1px;

    //   &:hover {
    //     color: $blue-bg;
    //   }
    // }
  }
  .content:hover {
        .btn-bd {
          background: #fff;
          color: $blue-bg;
          text-decoration: none;
          border: 1px $blue-bg solid;

          span {
            background: url(../images/ar-link.png) right no-repeat;
            background-size: 4px;
          }

          &::before, &::after {
            height: 100%;
            width: 100%;
            transition: height $timing $easeInOutQuint, width $timing $timing $easeInOutQuint;
          }
        }
  }
}

#pJobList .bod-intro-wrap ul li img {
  margin-bottom: 25px;
}
#pJobList .bod-intro-wrap ul li .info-founder {
  // padding-bottom: 3px;

  h3 {
    font-size: 24px;
  }
}

.why-ipp {
  width: 100%;
  height: 450px;
  background-size: cover !important;
  position: relative;

  .copy {
    width: 650px;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);

    h3 {
      font-size: 48px;
          color: $blue-bg;
          line-height: 60px;
          padding-bottom: 10px;
    }
  }
}
.linkedin {
  text-align: center;
  font-size: 18px;
  color: #2c2c2c;
  padding-top: 30px;
  a {
    margin-left: 23px;
  }

  a span {
      display: inline-block;
      position: relative;
      padding-left: 50px;
    &:after {
      content: '';
      display: inline-block;
      width: 28px;
      height: 28px;
      background: url(../images/icon-linked.png) center no-repeat;
      background-size: 28px;
      position: absolute;
      top: 10px;
      left: 0px;
    }
    
  }
} 
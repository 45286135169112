.top-page {
	text-align: center;
	padding-top: 50px;

	h2 {
		color: $blue-bg;
    margin: 0 auto;
    margin-bottom: 15px;
    font-size: 48px;
    max-width: 690px;
    line-height: 60px;
	}

	p {
		display: inline-block;
		margin: 0 auto;
		max-width: 905px;
		line-height: 25px;
		padding: 0 10px;
	}
}
#pNews .top-page {
  padding-top: 37px;
}

.banner-news {
	padding: 50px 0;


	.img {
		width: 100%;
		float: left;

		img {
			width: 100%;
		}
	}

	.copy {
		width: 47%;
		float: right;
		padding: 0 110px 0 50px;
    position: absolute;
    top: 50%;
    right: 0;
    -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);

		p {
			font-size: 16px;
		    font-family: 'sfu_universregular';
		    color: #b4b4b4;
		    text-transform: uppercase;
		    padding-bottom: 12px;
		}

		.tt {
			font-family: 'playfair_displaybold';
			font-size: 32px;
			color: $blue-bg;
			line-height: 40px;
			padding-bottom: 20px;
			display: inline-block;
			width: 100%;

      &:hover {
        text-decoration: none;
      }
		}
		.btn-bd {
			font-size: 13px;
      border: 1px #000 solid;

      svg rect {
        stroke: #a5a5a5;
      }
		}
	}
}
#pNews .bx-wrapper {
	margin-bottom: 0;
  max-height: 450px;
  overflow: hidden;
}
#pNews .bx-wrapper .bx-viewport {
	background: #fff;

}
#pNews .bx-wrapper .bx-pager.bx-default-pager a {
    border: 1px #fff solid;
}
#pNews .bx-wrapper .bx-pager.bx-default-pager a:hover,
#pNews .bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #fff;
}
.bx-wrapper .bx-pager {
  bottom: 11px;
}

.select-op {
  width: 100%;
  float: left;
  padding: 50px 13px;
  // border-top: 1px #d7d7d7 solid;
  // border-bottom: 1px #d7d7d7 solid;
}

.select-op p {
  display: inline-block;
  float: left;
  margin-right: 35px;
  font-size: 15px;
  line-height: 50px;
}

.select-op .fancy-select {
  float: left;
  margin-right: 50px;
}
#pNews .select-op .fancy-select:nth-child(4) {
	margin-right: 0;
}
#pNews .select-op .fancy-select:nth-child(4) .trigger {
  width: 240px;
}
#pJobList .select-op .fancy-select .trigger {
  width: 240px;
}
#pJobList .select-op {
	// margin-top: 57px;
  padding: 50px 0px;
}
#pJobList .select-op .fancy-select {
  margin-right: 15px;
}
.select-op .search-job {
  width: 260px;
  float: right;
}

.select-op .search-job input[type='button'] {
  display: block;
  float: left;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  background: $blue-bg url(../images/icon-search2.png) center center no-repeat;
  background-size: 20px;
  transition: background-color 0.3s ease;
  border-radius: 0;
}

.select-op .search-job input[type='button']:hover {
  background: #000f37 url(../images/icon-search2.png) center center no-repeat;
}

.select-op .search-job input[type='text'] {
  display: block;
  float: left;
  background: none;
  font-family: 'sfu_universlight';
  border: 1px solid #c7c7c7;
  color: #959595;
  width: 210px;
  height: 50px;
  font-size: 13px;
  padding: 6px 24px 9px 35px;
  border-radius: 0;
  box-shadow: none;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.window .select-op .search-job input[type='text'] { 
  padding-top: 9px;
}

.select-op .search-job input[type='text']:hover, .select-op .search-job input[type='text']:focus {
  border-color: $blue-bg;
  color: $blue-bg;
}

.select-op .search-job ::-webkit-input-placeholder {
  color: #4c4c4c;
}

.select-op .search-job :-moz-placeholder {
  /* Firefox 18- */
  color: #4c4c4c;
}

.select-op .search-job ::-moz-placeholder {
  /* Firefox 19+ */
  color: #4c4c4c;
}

.select-op .search-job :-ms-input-placeholder {
  color: #4c4c4c;
}





.news-wrap{
  padding: 0 0 50px 0;

  .container {
    max-width: 1170px;
  }

  .list-news {
    width: 100%;
    display: block;
    clear: both;
    overflow: hidden;

    li {
      width: 33.33333%;
      display: block;
      float: left;
      padding: 0 15px;
      margin-bottom: 50px;
      opacity: 0;
   	}
  }

}


.paging {
  width: 100%;
  text-align: center;
  height: 60px;
  position: relative;
}

.paging div {
  position: absolute;
  height: 60px;
  width: 100%;
}

.paging a {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: 1px #c3c3c3 solid;
  font-size: 14px;
  color: #959595;
  position: relative;
}

.paging a:hover {
  background: #ebebeb;
  z-index: 2;
  text-decoration: none;
}

.paging span {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: 1px #c3c3c3 solid;
  font-size: 14px;
  color: #fff;
  position: relative;
  background: #253a71;
  margin-left: -1px;
}

.newsdetail-wrap {
	padding-bottom: 104px;
}

.news-content {
	width: 1004px;
	background: #fff;
	margin: 0 auto;
	margin-top: 60px; 

	.copy {
		padding: 80px 100px 65px 100px;
		text-align: left;

		p {
			padding-bottom: 27px;
      em {
        font-style: italic;
      }
			strong {
				font-family: 'sfu_universbold';
			}
		}

		img {
			max-width: 100%;
			// margin-bottom: 27px;
		}
	}
}

.banner-main-news {
	position: relative;

	& > img {
		width: 100%;
	}

	ul {
		display: block;
		position: absolute;
		top: -3px;
		right: -36px;

		li {
			margin-bottom: 10px;

      img {
        width: 20px;
      }

			img:hover {
				opacity: 0.8;
			}
		}
	}
}

.crs-news {
	margin-top: 40px;
  // opacity: 0;
}

.media-news {
  font-size: 14px;
  color: #4c4c4c;
  text-transform: uppercase;
  padding-left: 69px;
  padding-top: 30px;
  clear: both;
  overflow: hidden;

  span {
    display: inline-block;
    float: left;
    margin-right: 30px;
    padding-bottom: 10px;
  }
  em {
    display: inline-block;
    float: left;
    
    img {
      margin-right: 30px;
      margin-bottom: 10px;
    }
  }
}

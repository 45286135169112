// Functions ------------------------------------------------------------------

// Strip-units ----------------------------------------------------------------
//
// Converts any typed variable to a regular integer
//
// $number: 	The value you want to unify

@function strip ($number) {
  @return $number / ($number * 0 + 1);
}

// Em -------------------------------------------------------------------------
//
// Converts a target value to em's, based on a context
//
// $target: 	The target integer
// $context: 	Base for conversion, defaults to $doc-em-factor

@function em ($target, $context: $base-font-size) {

  $context: strip($context);
  $target: strip($target);

  @return ($target/$context)*1em;
}

// Rem ------------------------------------------------------------------------
//
// Converts a target value to rem's
//
// $target: 	The target integer

@function rem ($target) {

  $context: strip($base-font-size);
  $target: strip($target);

  @return ($target/$context)*1rem;
}

// Tint -----------------------------------------------------------------------
//
// Add percentage of white to a color
// Taken from Bourbon source

@function tint ($color, $percent) {
  @return mix(#fff, $color, $percent);
}

// Shade ----------------------------------------------------------------------
//
// Add percentage of black to a color
// Taken from Bourbon source

@function shade ($color, $percent) {
  @return mix(#000, $color, $percent);
}


// Z Level ----------------------------------------------------------------------
//
// Get the z-level index of desired layer
@function z($layer) {
  @if not map-has-key($z-level, $layer) {
    @warn "No layer found for `#{$layer}` in $z-level map. Property omitted.";
  }

  @return map-get($z-level, $layer);
}

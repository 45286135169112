.banner-home-mb {
  width: 100%;
  display: none;
}
.hd-mobile {
  display: none;
}
.btn-menu {
  display: none;
  width: 58px;
  height: 58px;
  background: #112456 url(../images/menu-mb.png) center center no-repeat;
  background-size: 21px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 22;
}

.btn-menu.active {
  background: #253a71 url(../images/close-mb.png) center center no-repeat;
  background-size: 21px;
}

.btn-search-mb {
  display: none;
  width: 58px;
  height: 58px;
  background: url(../images/icon-search2.png) center center no-repeat;
  background-size: 19px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 22;
}

.btn-search-mb input {
  display: none;
  width: 100%;
  background: #fff;
  border: none;
  height: 50px;
  padding: 0 20px;
  line-height: 50px;
  position: fixed;
  top: 58px;
  right: 0;
  font-family: 'sfu_universlight';
  font-size: 14px;
  border-radius: 0;
}

.btn-search-mb.active {
  background: #253a71 url(../images/close-mb.png) center center no-repeat;
  background-size: 21px;
}

.lang-mb {
  width: 100%;
  padding: 40px 20px;
  background: #253a71;
  clear: both;
  overflow: hidden;
}

.lang-mb p {
  display: block;
  float: left;
  font-size: 15px;
  text-transform: uppercase;
  color: #eec2a3;
}

.lang-mb p a {
  color: #eec2a3;
  display: inline-block;
  padding: 0 10px;
}

.lang-mb p a.active {
  color: #eec2a3;
}

.lang-mb p a:last-child {
  padding-right: 0;
}

.lang-mb p:last-child {
  float: right;
}
.img-desktop {
  width: 100%;
}
.img-mobile {
  display: none;
  width: 100%;
}
.btn-filter,
.showmb-tl {
  display: none;
}
#show-mobile {
  display: none;
}

@media screen and (max-width: 1139px) {
	// .container {
	// 	padding-left: 10px;
	// 	padding-right: 10px;
	// }
	.hd-wrapp {
		padding: 0 10px;
		max-width: 1004px;
	}

	.menuscroll nav ul li {
		padding: 0 20px !important; 
	}
  nav ul li {
    padding: 0 25px;
  }


	.slick-next {
		right: 9px;
	}
	.slick-prev {
		left: 9px;
	}
	.slick-arrow {
		background-color: rgba(255, 255, 255, 0.7);
		border: 1px solid #dbdbdb;
		z-index: 2;
	}

	.the-number ul li span {
		font-size: 31px;
	}
	.houses-top-wrap .copy {
		right: 10px;

		h3 {
			font-size: 30px;
		}
	}

	.banner-home .copy {
		max-width: 796px;
	}
  // #navGroup .subnav-item {
  //   padding-left: 178px;
  // }
  // #navHouses .subnav-item {
  //   padding-left: 292px;
  // }
  // #navTalents .subnav-item {
  //   padding-left: 520px;
  // }

  // .hasmenuscroll {

  //   #navGroup .subnav-item {
  //     padding-left: 292px;
  //   }
  //   #navHouses .subnav-item {
  //     padding-left: 398px;
  //   }
  //   #navTalents .subnav-item {
  //     padding-left: 605px;
  //   }
  // }

  .about-wrapp {
        padding: 37px 10px 20px 10px; 
  } 
  .mall-wrap {
    padding: 0 10px;
    padding-top: 50px;
  }
  .loyalty {
    padding-top: 50px; 
  }
  .invest-boss {
    padding: 50px 10px 0 10px; 
  }
  #pInvest .info-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
  .view-item-wrapp {
    padding-left: 10px;
    padding-right: 10px;
  }
  .value-wrap .container{
    padding: 0 10px;
  }
  .timeline-wrap {
    padding: 0 10px;
  }
  .linetime-item {
    padding-left: 10px;
  }
  .linetime-item:nth-child(2n) {
    padding-right: 10px;
  }
  .linetime-item .copy h3 {
    width: 38%;
  }
  .banner-news {
    padding: 50px 10px;
  }
  .select-op p {
    margin-right: 31px;
  }
  
  .select-op .fancy-select {
    margin-right: 20px;
  }
  .news-wrap .list-news li {
    padding: 0 20px;
  }
  .select-op {
    padding: 50px 20px;
    .fancy-select .trigger {
      width: 293px;
    }
  }
  #pJobList .select-op .fancy-select .trigger {
    width: 217px;
  }
  .news-content {
    width: 933px;
  }
  .job-wrap {
        padding: 50px 10px;
  }
  .select-op .search-job {
    width: 233px;
  }
  .select-op .search-job input[type='text'] {
    width: 183px;
  }
  .select-op .search-job {
    float: left;
  }
  .job-detail {
    padding-left: 10px;
    padding-right: 10px;
  }
  .testimo-wrap .testimo-item .copy {
    float: left;
    position: relative;
    top: 0;
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .breadcrumb,
  .banner-faq,
  .faq-detail-wrap,
  .contact-wrap,
  .add-ct {
    padding-left: 10px;
    padding-right: 10px;
  }
  footer .container {
    padding: 0 10px;
  }
  .contact-wrap .copy {
    width: 35%;
  }
  .contact-wrap .ct-form {
    width: 65%;
    padding-left: 100px;
  }
  .policy-wrap,
  .map-detail-wrap,
  .all-brand-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
  .video-wide {
    height: 400px;
  }
  .popup-youtube {
    width: 928px;
    height: 540px;
  }
  .colum-5 li {
    width: 25%;
  }
  .all-b-l,
  .all-b-r {
    display: none !important;
  }

  .brand-home {
    margin: 0 -20px;
    padding-left: 10px;
    padding-right: 10px;
  } 
  .brand-home li {
    padding: 0 10px;
  }

}

@media screen and (max-width: 1024px) {
//   html.hover-active *:hover {
//     pointer-events: none !important;
// }
	// .hd-desktop h1 {
	// 	margin-left: -45px;
	// 	top: 33px;

	// 	img {
	// 		width: 90px;
	// 	}
	// }
	// nav ul li {
	// 	padding: 0 7px;
	// }
	// .hd-desktop nav li:nth-child(4) {
 //    	margin-right: 101px;
	// }
	// .hd-desktop nav li:nth-child(5), .hd-desktop nav li:nth-child(6), .hd-desktop nav li:nth-child(7) {
 //    	padding: 0 16px;
	// }
	// .menuscroll nav ul li {
 //    	padding: 0 9px !important;
	// }
	// .hd-desktop .logo-scroll {
	// 	left: 10px;
	// }

input[type=text],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0 !important;
}
	header {
    display: none !important;
    height: 58px;
  }
  header h1 {
    text-align: center;
    margin-top: 9px;
    display: inline-block;
    width: 100%;
  }
  .hd-mobile {
    display: block !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    .hd-wrapp {
      height: auto;
    }
  }
  .logo img {
    width: 100px;
  }
  .btn-menu {
    display: block;
  }
  .btn-search-mb {
    display: block;
  }
  .ovl-mb {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;
    background: #000;
    opacity: 0.5;
    display: none;
  }
  .mb-wrap {
    width: 262px;
    height: 100%;
    position: fixed;
    top: 0;
    left: -240px;
    background: #253a71;
    z-index: 5;
    display: none;
    padding: 0 0 0 0;
    overflow: auto;
    box-shadow: 0 0 30px #1c0821;
  }
  nav ul {
    width: 100%;
    text-align: center;
    padding: 10px 20px 0 20px;
    display: block;
    float: left;
  }
  nav ul li {
    width: 100%;
    padding: 0;
    overflow: hidden;
    text-align: left;
    position: relative;
    display: block;
    float: left;
    width: 100%;
  }
  nav ul li a {
    width: 100%;
    font-size: 13px;
    padding: 10px 0;
    border-bottom: 1px #46527c solid;
    position: relative;
    display: inline-block;
  }
  nav ul li a span {
    display: inline-block;
    padding-bottom: 2px;
    border-bottom: 1px #96141e solid;
  }
  nav ul li a.active, nav ul li a:hover {
    background: none;
  }
  nav ul li a.active span, nav ul li a:hover span {
    border-bottom: 1px #fdde8c solid;
  }
  nav ul li.sub-mb > a {
    padding-right: 15px;
  }
  nav ul li.sub-mb > span {
    display: block;
    position: absolute;
    top: -2px;
    right: -7px;
    width: 39px;
    height: 39px;
    background: url(../images/icon-plus.png) center no-repeat;
    background-size: 10px;
  }
  nav ul li.active-nav > span {
    background: url(../images/icon-minus.png) center no-repeat;
    background-size: 10px;
  }
  nav ul li a:hover:after, nav ul li a.active:after {
    display: none;
  }
  nav ul li div {
    display: none;
    padding: 9px 0;
    border-bottom: 1px #45517d solid;
  }
  nav ul li div a {
    font-size: 14px;
    font-family: 'sfu_universlight';
    text-transform: none;
    padding: 8px 0;
    border-bottom: none;
  }
  nav ul li div a.active {
    background: none;
    text-decoration: underline;
  }
  nav ul li div a:before {
    display: none;
  }
  .right-hd {
    position: relative;
    top: auto;
    right: auto;
    text-align: center;
    padding-top: 0;
  }
  .right-hd ul {
    width: 100%;
    text-align: center;
  }
  .right-hd ul li {
    display: inline-block;
    float: none;
  }
  .ct-search {
    float: none;
    width: 100%;
    display: inline-block;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 0 0;
  }
  .ct-search input[type='text'] {
    width: 100% !important;
    height: 61px;
    background: #96141e url(../images/icon-search.png) 40px center no-repeat !important;
    padding-left: 60px;
    margin-left: 0;
    border-radius: 0;
    text-transform: none;
  }
  .ct-search input[type='text']:hover, .ct-search input[type='text']:focus {
    background: #fff url(../images/icon-search.png) 40px center no-repeat !important;
    color: #000;
  }
  .ct-search input[type='submit'] {
    width: 40px;
    height: 10px;
    border: 1px #ddb373 solid;
    visibility: hidden;
    opacity: 0;
  }
  #main {
    padding-top: 58px;
  }



	.banner-home {
    height: auto !important;
    max-height: 99999px; 
  }
  .banner-home-mb {
    display: block;
  }
  .bxslider {
    height: auto;
  }
  .bxslider li {
    height: auto;
    background: none !important;
  }
  .banner-home .copy {
    transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    top: 0;
    background: #253a71;
    padding: 20px 20px 70px 20px;
    text-align: center;
    max-width: 100%;
    
    h3 {
    	color: #fff;
    	font-size: 25px;
      text-shadow: none;

    	strong {
    		font-size: 30px;
    	}
    }
    a {
    	color: #fff;
    	border: 1px #fff solid;

    	span {
			background: url(../images/ar-link-mb.png) right no-repeat;
    		background-size: 4px;
    		// height: 7px;
		}
    }
  }
  .banner-home .copy div {
    margin-left: 0;
    width: 100%;
  }
  .banner-home .copy p {
    width: 100%;
  }
  .bx-wrapper .bx-controls-direction a {
    margin-top: -195px;
  }
  .bx-wrapper .bx-next:hover,
  .bx-wrapper .bx-prev:hover {
    margin-top: -206px;
  }
  .bx-wrapper {
    margin: 0;
  }

  .btn-bd svg {
    display: none;
	}


  .copy.animation div > * {
	  opacity: 1 !important;
	  -webkit-transform: translate3d(0, 0, 0) !important;
	  transform: translate3d(0, 0, 0) !important;
	}

	.item-news .copy {
		padding: 10px;
	}

	.btn-bd {
		border: 1px #d0d0d0 solid;
		
	}

	.item-wrap-home ul li {
		padding: 0 15px;
	}
	.item-wrap-home ul li .copy h2 {
		font-size: 30px;
	}

	.list-houses-wrap {
		margin-left: 0;
	}
	.side-menu {
		display: none;
	}
  .list-houses {
    padding: 0 10px;
  }
  .list-houses-wrap {
    padding-bottom: 0;
  }
  .breadcrumb {
    padding: 10px;
  }
  .banner-page div {
    height: 320px;
  }
  #pNews .bx-wrapper .bx-controls-direction a {
        margin-top: -25px;
  }
  .banner-news .copy {
    padding-left: 0;
    padding-top: 30px;
  }
  .banner-news .copy .tt {
    font-size: 22px;
    line-height: 25px;
  }
  .talents-home-wrapp .view-item .copy {
    position: relative;
        top: 0;
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .banner-home .bx-wrapper .bx-pager {
    bottom: 20px;
  }
  .banner-home .ar-down {
    display: none;
  }
  .mind-wrap {
    display: none;
  }
  #show-mobile {
    display: inline-block;
  }
  .viewpage-wrap {
    padding: 50px 0 10px 0;

    &.pad-top-none {
      padding-top: 50px !important;
    }
  }
  .dgt_arrow {
    display: none;
  }
}

@media screen and (max-width: 1023px) { 
	.houses-top-wrap .img,
	.houses-top-wrap .copy {
		width: 50%;
	}

	.houses-top-wrap .copy {
		top: 52%;

		h3 {
			font-size: 25px;
		}

		p {
			font-size: 16px;
		}
	}
	.the-number {
		padding-bottom: 5px;
	}
	.the-number ul li {
		width: 33.3333%;
		text-align: center !important;
		float: left;
		margin: 0;
		padding: 0 10px;

		span {
			width: 100% !important;
			display: inline-block;
			line-height: 30px;
		}

		em {
			width: 100%;
			margin-left: 0;
			text-align: center;

			br {
				display: none;
			}
		}
	}

	
	#pHousesDetail .houses-item .copy h2 {
		font-size: 32px;
	}
	.brand-wrap ul li {
		padding: 0 7px;
		width: 33.3333%;
	}
	.bod-intro-wrap ul li {
		padding: 0 15px;
	}

	.info-wrap .item .copy {
		padding: 20px 10px;

		h3 {
			font-size: 20px;
          line-height: 24px;
		}
	}
	.info-wrap .right .item .copy {
    	padding: 20px 20px 20px 20px;
	}
  footer .container {
    padding: 0 10px;
  }
  .info-wrap .right .item img {
    width: 115%;
  }
  .houses-item .copy .tt {
    padding-bottom: 10px;
    img {
      height: 25px;
    }
    p {
      padding-bottom: 20px;
    }
  }
  #pHousesDetail .banner-top .img {
    width: 50%;
  }
  .banner-top .copy,
  .pretext {
    width: 55%;
  }
  .pretext {
    bottom: 30px;
  }
  .banner-top .copy h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .mall-wrap ul li .copy {
    position: relative;
    top: 0;
    right: 0;
        -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    float: right;
  }
  .more-fb .content .copy {
    padding-right: 131px;
  }
  .more-fb .content .copy a {
    padding: 0;
    min-width: 126px;
  }
  .info-wrap .right .item {
    overflow: hidden;
  }
  .why-inves-wrapp ul li {
    padding: 0 10px;
  }
  .why-inves-wrapp ul li .img h4 {
    font-size: 20px;
    line-height: 25px;
  }
  .view-item-wrapp .view-item .copy {
    float: left;
    position: relative;
    top: 0;
    left: 0;
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .view-item-wrapp .view-item:nth-child(2n) .copy {
    float: right;
  }
  .linetime-item {
    padding-right: 30px;
  }
  .linetime-item:nth-child(2n) {
    padding-left: 30px;
  }
  .timeline-wrap {
    background-position: center 146px;
  }
  .top-page p {
    br {
      display: none;
    }
  }
  .select-op p{
    width: 100%;
    font-family: 'playfair_displaybold';
    font-size: 20px;
    color: #253a71;
  }
  .select-op {
    padding: 50px 10px;

    .fancy-select .trigger {
      width: 234px;
    }
  }
  .news-wrap .list-news li {
    padding: 0 10px;
  }
  
  .news-wrap .list-news li {
    margin-bottom: 20px;
  }
  .news-content {
    width: 100%;
    padding: 10px;
  }
  .banner-main-news ul {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    text-align: center;
    margin-top: 10px;

    li {
      display: inline-block;
      margin: 0 5px;
    }
  }
  .news-content .copy {
    padding: 37px 50px 10px 50px;
  }
  .newsdetail-wrap {
    padding-bottom: 30px;
  }
  // .testimo-wrap .testimo-item .copy {
  //     width: 55%;
  //     padding: 0 10px;
  //   }
    #pJobList .more-downtown .copy h3 {
    font-size: 25px;
    line-height: 30px;
    }
    .select-op .search-job {
        width: 248px;
    }
    .select-op .search-job input[type='text'] {
          width: 198px;
          padding-left: 30px;
    }
    .select-op .fancy-select {
      margin-right: 10px;
    }.list-job-wrapp .title p {
      letter-spacing: 0;
      font-size: 18px;
    }
    .list-job-wrapp .title p:first-child {
      padding-left: 20px;
    }
    .list-job-wrapp .list-job li a {
      padding-left: 20px;
    }
    .list-job-wrapp .list-job li .tt {
      padding-left: 20px;
    }
    .popup {
      width: 768px;
      margin: -320px 0 0 -384px;
    }
    .popup .left {
      width: 365px;
    }
    .popup .right {
      width: 374px;
      margin-right: 10px;
    }
    .popup .fancy-select {
      width: 183px;
    }
    .faq-wrap ul li {
      padding: 0 10px;
      margin-bottom: 10px;
    }
    .faq-wrap ul li .copy {
          padding: 10px;
    }
    .faq-wrap ul li .img a span {
      font-size: 20px;
    }
    .faq-detail-wrap .content {
      padding-left: 338px;
    }
    .newsletter-wrap {
      width: 376px;
    }
    .newsletter-wrap input[type='text'] {
          width: 320px;
    }
    .contact-wrap .copy {
      width: 40%;
    }
    .contact-wrap .ct-form {
      width: 60%;
      padding-left: 50px;
    }
    .contact-wrap .ct-form .btn-bg {
      margin-top: 10px;
    }
    .add-ct .map {
      width: 60%;
      height: 400px;
    }
    .add-ct .right-ct {
      width: 40%;
      padding-left: 10px;
    }
    .linetime-item .copy h3 {
      font-size: 50px;
    }

    .popup ul li .btn-bd {
      font-size: 11px;
      min-width: 132px;
      padding: 0 5px;
    }
    #pJobList .more-downtown .copy a {
      padding: 0 15px;
    }
    .banner-top .copy p {
      font-size: 17px;
    }
    .popup ul li .btn-bd.btn-file span {
      width: 148px;
      padding-right: 0;
    }
    .en .popup ul li .btn-bd.btn-file span {
      width: 127px;
      padding-right: 0;
    }
    .popup ul li .btn-bd.btn-letter span {
      padding-right: 0;
      width: 199px;
    }
    .en .popup ul li .btn-bd.btn-letter span {
      padding-right: 0;
      width: 219px;
    }

    #pJobList .select-op .fancy-select {
      margin-right: 0;
      width: 50%;
      padding-right: 5px;
      margin-bottom: 10px;

      &:nth-child(3n) {
        padding-right: 0;
        padding-left: 5px;
      }
    }
    #pJobList .select-op .fancy-select .trigger {
      width: 100%;

    }

    .select-op .search-job {
      width: 50%;
      padding-right: 50px;
      position: relative;
      padding-left: 5px;
    }
    .select-op .search-job input[type='text'] {
      width: 100%;
    }
    .select-op .search-job input[type='button'] {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
    }
    .map-detail-wrap .more-downtown h3 {
      font-size: 30px;
    }
    .op-map .fancy-select,
    .op-map a {
      margin-right: 10px;
    }
    .op-map .fancy-select .trigger {
      width: 187px;
    }
    #pHousesDetail .mind-wrap ul li .copy {
      padding: 0;
    }
    .popup-youtube {
      width: 697px;
      height: 397px;
    }
    .op-map .fancy-select {
      width: 50%;
      float: left;
      margin-bottom: 10px;
      margin-right:0;
      padding-right: 5px;

      &:nth-child(2) {
        padding-left: 5px;
        padding-right: 0;
      }
    }
    .op-map .fancy-select .trigger {
      width: 100%;
    }
    .op-map {
      padding: 20px 0;
    }
    
    .op-map a {
      padding: 5px 0 5px 30px;
    }
    .map-detail-wrap .more-downtown h3 {
      padding: 0 10px 20px 10px;
    }
    .award-banner-top .copy h3 {
      font-size: 25px;
      padding-bottom: 10px;
    }
    .award-banner-top .copy p {
      font-size: 15px;
    }
    .brand-home li h3 {
      font-size: 20px;
    }
    .brand-home li a {
      font-size: 12px;
    }
}
@media screen and (max-width: 767px) {

  .btn-scrollTop {
    display: none !important;
  }
  body {
    font-size: 13px;
  }
  .banner-home .copy {
    padding: 10px 10px 46px 10px;
  }
  .banner-home .copy h3 {
    line-height: 24px;
  }
  .banner-home .copy h3 strong {
    font-size: 20px;
    

    br {
      display: none;
    }
  }
  .banner-home .copy a {
    margin-top: 10px;
    width: 100%;
  }
  .animated {
    opacity: 1 !important;
  }
  .big-tt {
    font-size: 25px;
    line-height: 30px;
  }
  .news-wrap-home,
  .history-wrap-home {
    padding: 20px 10px;
  }
  .news-wrap-home ul {
    margin: 20px 0;
  }
  .item-news .tt {
    font-size: 15px;
    line-height: 21px;
    min-height: auto;
  }
  .item-wrap-home ul li .copy h2 {
    font-size: 20px;
  }
  .item-wrap-home ul li .img {
    margin-bottom: 10px;
  }
  .item-wrap-home ul li .copy {
    padding-right: 0;
    width: 100%;

    .btn-bd {
      min-width: 0;
      padding: 0 20px;
    }
  }
  .item-wrap-home ul li .copy p {
    padding-bottom: 10px;
  }
  .item-wrap-home {
    padding-bottom: 20px;
  }
  .link-ft {
    padding-top: 20px;
  }
  .link-ft h2,
  .link-ft-wrap > div,
  .history-wrap-home .copy,
  .houses-top-wrap .copy,
  .houses-item .copy,
  .more-downtown .copy,
  .banner-news .copy,
  .linetime-item .copy  {
    opacity: 1 !important;
    -ms-transform: translate(0, 0) !important;
      -webkit-transform: translate(0, 0) !important;
      transform: translate(0, 0) !important;

      &>* {
        opacity: 1 !important;
    -ms-transform: translate(0, 0) !important;
      -webkit-transform: translate(0, 0) !important;
      transform: translate(0, 0) !important;
      }
  }
  .link-ft-wrap {
    margin-top: 20px;
    padding-top: 0;
  }
  .link-ft-wrap > div {
    width: 100% !important;
    border: 0;
    
    padding: 0;
    margin-bottom: 0;
    opacity: 1;
    height: auto !important;
  }
  .link-ft-wrap > div h3 {
    padding: 7px 0;
    display: inline-block;
    position: relative;
    width: 100%;
    font-size: 12px;
    border-top: 1px #46527c  solid !important;
  }
  .link-ft-wrap > div h3.hassubft span {
    display: block;
    position: absolute;
    top: 5px;
    right: 0;
    width: 20px;
    height: 20px;
    background: url(../images/icon-plus.png) center no-repeat;
    background-size: 8px;
  }
  .link-ft-wrap > div h3.active-ft span {
    background: url(../images/icon-minus.png) center no-repeat;
    background-size: 8px;
  }
  .link-ft-wrap > div ul {
    width: 100% !important;
    margin-bottom: 0;
  }
  .link-ft-wrap > div ul li a {
    font-size: 13px;
    padding: 5px 0;
    width: 100%;
  }
  .sub-link-ft {
    margin-top: 0;
    border: none;
  }
  .ft-mb-wrap {
    display: none;
    clear: both;
    overflow: hidden;
    width: 100% !important;
    margin-bottom: 10px;
  }
  // .sub-link-ft .js-open-popup {
  //   width: 100%;
  //   padding: 20px 0 20px 20px;
  //   border-top: 1px #46527c solid;
  //   border-bottom: 1px #46527c solid;
  //   text-align: left;
  //   font-size: 16px;
  // }
  .sub-link-ft p {
    padding: 0;
    margin: 0;
    text-align: left;
    width: 100%;
  }
  .sub-link-ft a {
    margin-left: 0;
    margin-right: 16px;
    font-size: 12px;
  }
  .link-ft-wrap > div:nth-child(3) h3:first-child {
    margin-bottom: 0;
  }
  .link-ft-wrap > div:nth-child(4) h3:first-child {
    margin-bottom: 0;
  }
  .newsletter-wrap {
    width: 100%;
    padding-right: 50px;
    margin-left: 0;
    margin-top: 10px;
    position: relative;

    span {
      position: absolute;
      top: 0;
      right:0;
    }
  }
  #pHome .news-wrap-home ul li {
    width: 100%;
    margin-bottom: 10px;
    padding: 0;
  }
  // #pHome .item-news {
  //   padding-left: 110px;
  //   position: relative;

  //   .img {
  //     width: 100px;
  //     overflow: hidden;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //   }
  //   .copy {
  //     padding: 0;
  //     height: auto !important;
  //   }
  // }
  #pHome .item-news {
    position: relative;

    .img {
      width: 30%;
      float: left;
      overflow: hidden;
    }
    .copy {
      padding: 0;
      width: 70%;
      float: left;
      padding-left: 10px;
      height: auto !important;
    }
  }
  #pHome.item-wrap-home ul li {
    padding: 0 10px;
  }
  .history-wrap-home .img {
    float: none;
  }
  .history-wrap-home .copy {
    position: relative;
    top: 0;
    left: 0;
    padding: 10px 10px 0 10px;

    h2 {
      color: $blue-bg;
      opacity: 1;
    }
    p {
      padding-bottom: 10px;
       line-height: 20px;
       opacity: 1;

       br {
        display: none;
       }
    }
    a {
      border: 1px #d0d0d0 solid;
      opacity: 1;
    }
  }
  .about-wrapp {
    padding: 20px 10px 20px 10px;
  }
  .top-about p {
    line-height: 19px;
  }
  .info-wrap {
    padding-top: 20px;
  }
  .info-wrap .left {
    width: 100%;
    margin-bottom: 10px;

    .item {
      width: 100%;
      border: none;
      margin-bottom: 20px;

      &:last-child {
        float: right;
      }
    }
  }
  .info-wrap .right {
    width: 100%;
    padding: 0;
    padding-bottom: 20px;

    .item {
      height: auto !important;
      border: none;
      min-height: 1px !important;
      .copy {
        min-height: 1px !important;
        position: relative;

        
      }
      img {
          width: 100% !important;
          display: inline-block;
        }
    }

  }
  .info-wrap .item {
    margin-bottom: 10px;
    background: none !important;

    .copy {
      padding: 10px 0 10px 0 !important;
      background: none !important;
      min-height: 1px !important;

    }
    &:hover {
        .copy {
          background: none;

          h3 {
            color: #253a71;
            .ar-link {
              background: url(../images/ar-link-hv.png) no-repeat;
            }
          }
          p {
                color: #4c4c4c;
          }
        }
      }
  }
  .number-wrap {
    padding: 20px 10px 0 10px;
  }
  .the-number ul li {
    width: 100%;
    margin-bottom: 20px;
  }
  .the-number ul li span {
    font-size: 23px;
    line-height: 25px;
  }
  .the-number ul li em {
    font-size: 15px;
  }
  .bod-intro-wrap ul li {
    width: 100%;
    padding: 15px 10px;
    border-bottom: 1px #cccbca solid;
    margin-bottom: 0;

    // &:last-child {
    //   border-bottom: none;
    // }
  }
  .bod-intro-wrap{
    padding-top: 20px;
  }
  .bod-intro-wrap ul {
    margin-top: 20px;
  }
  .bod-intro-wrap ul li {
    margin-top: 0;
    height: auto !important;
  }
  .info-founder h3 {
    font-size: 20px;
  }
  .leader-wrap {
    padding: 20px 0;
  }
  .slick-slide {
    padding: 0px 10px;
  }
  .leader {
    padding-top: 0;
    margin-top: 20px;
  }
  .houses-top-wrap {
    padding-bottom: 20px;
  }
  .houses-top-wrap .img, .houses-top-wrap .copy {
    width: 100%;
  }
  .houses-top-wrap .img {
    padding-top: 0;
  }
  .houses-top-wrap .copy,
  .houses-item .copy,
  .more-downtown .copy {
    top: 0;
    position: relative;
    width: 100%;
  }
  .list-houses-wrap {
    padding-top: 20px;
  }
  .houses-item .img {
    width: 100%;
  }
  .houses-item .copy {
    padding: 0 10px 20px 10px;
  }
  .houses-item .copy p {
    font-size: 14px;
  }
  .list-houses ul li {
    margin-bottom: 20px;
  }
  .brand-wrap .container {
    padding: 0 5px;
  }
  .brand-wrap ul li {
    width: 50%;
  }
  .cap {
    padding-top: 20px;
    line-height: 20px;

    br {
      display: none;
    }
  }
  .tophouse {
    padding-bottom: 20px;
  }
  .brand-wrap ul {
    margin-top: 20px;
    li {
      margin: 0;
      margin-bottom: 20px;
    }
  }
  .brand-wrap .ct-btn {
    padding-bottom: 20px;
  }
  #pHousesDetail .news-wrap-home {
    padding: 20px 0;
  }
  .banner-top .copy h2 img {
    height: 18px;
    max-width: 999px;
  }
  .banner-top .copy p {
    padding-bottom: 0;
    font-size: 14px;
  }
  .more-downtown{
    padding: 20px 0;
  }
  .more-downtown .copy h3 ,
  .more-downtown .copy p {
    color: $blue-bg;
  }
  .crs-news {
    padding-top: 0;
    margin-top: 20px;
  }
  .banner-top .copy h2 {
    font-size: 25px;
    line-height: 31px;
        padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .mall-wrap ul li .img {
    width: 100%;
    padding-right: 0 !important;
    padding-left: 0 !important;
    text-align: center;
  }
  .mall-wrap ul li .copy {
    width: 100%;
    text-align: left;
    padding-left: 0;
    padding-top: 10px;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .mall-wrap ul li .copy p {
    line-height: 20px;
  }
  .loyalty {
    padding-top: 20px;
  }
  .more-fb {
    margin: 20px 10px 0 10px;
    padding: 20px 0;
  }
  .more-fb .content .left {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }
  .more-fb .content .right {
    width: 100%;
    padding-left: 0;
  }
  .more-fb .content .copy {
    padding-right: 0;
    text-align: center;
  }
  .more-fb .content .copy h3 {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .more-fb .content .copy a {
    position: relative;
  }
  .mall-wrap ul li {
    margin-bottom: 20px;
  }
  .more-downtown .copy {
    padding-top: 0;
  }
  .more-downtown .copy h3 br {
    display: none;
  }
  .banner-top .copy h2 img {
    height: auto;
    max-width: 100%;
  }
  .invest-boss .img {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }
  .invest-boss .copy {
    width: 100%;
    padding-left: 0;
  }
  .invest-boss {
    padding-top: 20px;
  }
  .invest-boss .img {
    margin-bottom: 10px;
  }
  .invest-boss .copy h3 {
    font-size: 20px;
  }
  .invest-boss .copy div h4 {
    font-size: 20px;
  }
  // .why-inves-wrapp ul li { 
  //   width: 50%;
  // }
  .why-inves-wrapp ul li {
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px #c2c2c2 solid;
    height: auto !important;

    // .copy {
    //   height: auto !important;
    // }

    &:last-child {
      border-bottom:none;
    }
  } 
  .why-inves-wrapp ul {
    margin-top: 20px;
  } 
  .why-inves-wrapp ul li .img {
    margin-bottom: 0;
  }
  .why-inves-wrapp ul li .img h4 {
    position: relative;
    top: 0;
    left: 0;
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    padding: 10px 0;
    text-align: left;
    text-shadow: none;
    color: #253a71;

    br {
      display: none;
    }
  }
    .why-inves-wrapp ul li .img:after {
      display: none;
    }

    .btn-w-bg {
       background: #253a71;
       color: #fff;

       span {
        background: url(../images/ar-link-mb.png) right no-repeat;
        background-size: 4px;
       }
    }
    .section-page {
          padding: 20px 0;
    }
    .view-item-wrapp {
      padding-top: 20px;
    }
     .view-item-wrapp .view-item {
      margin-bottom: 20px;
     }
    .view-item-wrapp .view-item .img {
      width: 100% !important;
      padding: 0 !important;
    }
    .view-item-wrapp .view-item .copy {
      width: 100% !important;
      padding: 10px 0 !important;
    }
    #pIppImpact .view-item .copy h3 {
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 10px;
    }
    .view-item-wrapp .view-item .copy p {
      line-height: 20px;
    }
    .mind-wrap ul li {
      padding: 0 5px;
    }
    .mind-wrap {
      padding-right: 5px;
      padding-left: 5px;

      ul {
        margin-top: 20px;
      }
    }
    #pIppImpact .mind-wrap ul li p {
      font-size: 15px;
      line-height: 20px;
    }
    .view-item-wrapp .view-item .copy h3 {
      font-size: 20px;
      line-height: 24px;
      border: 0;
      padding: 0;
    }
    .value-wrap h2 {
      margin-bottom: 20px;
    }
    .value-wrap ul li {
      padding-right: 0;
    }
    .value-wrap ul li:nth-child(2n) {
      padding-left: 0;
    }
    .value-wrap ul li {
      width: 100%;
      margin-bottom: 20px;
      min-height: 1px;
    }
    .value-wrap ul li .copy {
      height: auto !important;
    }
    .value-wrap ul li .copy h3 {
      font-size: 20px;
      padding-bottom: 0;
    }
    .value-wrap ul li .copy p {
      line-height: 20px;
    }
    // .value-wrap ul li .copy {
    //   padding-left: 0;
    // }
    // .value-wrap ul li .copy img {
    //   display: inline-block;
    //   position: relative;
    //   top: 0;
    //   -ms-transform: translate(0, 0);
    // -webkit-transform: translate(0, 0);
    // transform: translate(0, 0);
    // }

    .mind-wrap ul li h3 {
      font-size: 20px;
      padding-bottom: 5px;
    }
    .mind-wrap ul li p {
      line-height: 20px;
    }
    .mind-wrap ul li .img {
      margin-bottom: 10px;
    }
    .timeline-wrap {
      background: none;
      height: auto;
      padding-top: 20px;
    }
    .linetime-item {
      width: 100%;
      position: relative;
      padding: 0 !important;
      top: auto;
      margin-bottom: 20px;

    }
    .linetime-item .marker{
      display: none !important;
    }
    .linetime-item .copy {
      padding-left: 200px !important;
      position: relative;
      min-height: 110px;

      .img {
        width: 190px;
        position: absolute;
        top: 0;
        left: 0;
      }
      h3 {
        width: 100%;
        font-size: 40px;
        line-height: 40px;
        padding-bottom: 10px;
      }
      p {
        width: 100%;
        padding-top: 10px;
        line-height: 20px;
      }
    }

    .banner-news .copy {
      width: 100%;
      position: relative;
      padding: 10px 10px 40px 10px;
      position: relative;
      top: 0;
    }
    .top-page {
      padding-top: 20px;
    }
    .top-page h2 {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    .top-page p {
      line-height: 20px;
    }
    .banner-news {
      padding: 20px 10px;
    }
    #pNews .bx-wrapper .bx-controls-direction a {
      display: none;
    }
    #pNews .bx-wrapper .bx-pager.bx-default-pager a:hover, #pNews .bx-wrapper .bx-pager.bx-default-pager a.active {
      background: #253a71;
    }
    #pNews .bx-wrapper .bx-pager.bx-default-pager a {
          border: 1px #253a71 solid;
    }
    .select-op {
      padding: 0 10px 20px 10px;
      display: none;

      p {
        display: none;
      }
    }
    .select-op .fancy-select {
      width: 100% !important;
      margin-right: 0 !important;
      margin-bottom: 10px !important;
      padding: 0 !important;
      .trigger {
        width: 100% !important;
      }
    }
    #pNews .select-op .fancy-select:nth-child(4) {
      .trigger {
        width: 100%;
      }
    }
    .news-wrap .list-news li {
      width: 50%;
      margin-bottom: 10px;
    }
    .item-news .copy {
      padding: 10px 0;
    }
    .item-news:hover .copy {
      background: none;
    }
    .item-news:hover .copy p {
      color: #aeacac;
    }
    .item-news:hover .tt {
      color: #253a71;
    }
    .news-wrap {
      padding-bottom: 20px;
    }

    .paging a,
    .paging span {
      width: 35px;
      height: 35px;
      line-height: 32px;
      margin: 0;
    }
    .news-content {
      margin-top: 20px;
    }
    .news-content .copy {
      padding: 10px 10px 0 10px;
      text-align: left;
    }
    .news-content .copy img {
      width: 100%;
    }
    .news-content .copy p {
      padding-bottom: 10px;
    }
    .page404 {
      height: auto;
      padding: 30px 10px;
      img {
        max-width: 100%;
      }
    }
    .policy-wrap .content {
      padding: 10px;
      margin-top: 20px;
    }
    .policy-wrap .content div {
      padding-bottom: 10px;
    }
    .job-wrap {
      padding: 20px 10px;
    }
    .talents-home-wrapp {
      padding-top: 20px;
    }
    .talents-home-wrapp .view-item .copy {
      padding-right: 10px;

      p {
         padding-bottom: 10px;
         line-height: 20px; 
      }
      h3 {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .talents-home-wrapp .view-item .img {
      width: 100%;
      padding-left: 0;
      float: none;

    }
    .talents-home-wrapp .view-item .copy {
      width: 100%;
      padding-right: 0;
      padding-top: 10px;
    }
    .testimo-item:nth-child(2n) {
      background: #e7e7e7;
    }
    .testimo-wrap .testimo-item {
      padding: 10px;
      margin-bottom: 0;
    }
    .testimo-wrap .testimo-item .copy p {
      font-size: 14px;
      line-height: 20px;
      padding-bottom: 10px;
    }
    .testimo-wrap .testimo-item .img {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      float: none;;
      img {
        width: auto;
        max-width: 100%;
      }
    }
    .testimo-wrap .testimo-item .copy {
      width: 100%;
      position: relative;
      top: 0;
          -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);

      h3 {
        font-size: 20px;
        padding-bottom: 10px;
      }

    }
    .testimo-wrap {
      padding-top: 20px;
    }

    .testimo-wrap .btn-bd {
      width: auto;
      padding: 0 6px;
    } 
    #pJobList .more-downtown {
      margin: 0;
    }
    #pJobList .more-downtown .copy {
      padding: 0;
      padding-top: 10px;
      text-align: center;
      width: 100%;

      h3 {
        font-size: 20px;
        padding-bottom: 10px;
        br {
          display: none;
        }
      }
    }
    .linkedin {
      padding-top: 20px;
    }
    .linkedin a {
      display: block;
      width: 250px;
      margin: 0 auto;
      margin-top: 10px;
    }
    #pJobList .bod-intro-wrap ul li img {
      margin-bottom: 10px;
    }
    .info-founder {
      padding-bottom: 10px;

    }
    #pJobList .bod-intro-wrap ul li .info-founder h3 {
      font-size: 20px;
    }
    .bod-intro-wrap ul li p {
      line-height: 20px;
    }
    #pJobList .select-op {
      padding: 20px 0px;
    }
    #pJobList .select-op .fancy-select .trigger {
      width: 100%;
    }
    .select-op .search-job {
      width: 100%;
      padding-right: 50px;
      position: relative;
      padding-left: 0;
    }
    .select-op .search-job input[type='text'] {
      width: 100%;
      padding-left: 10px;
    }
    .select-op .search-job input[type='button'] {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
    }
    .list-job-wrapp {
      overflow: auto;
      padding-bottom: 20px;
    }
    .list-job-wrapp .title {
      width: 768px;
      padding: 15px 0;
      display: none;
    }
    .list-job-wrapp .list-job {
      width: 100%;
    }
    .list-job-wrapp .list-job li {
      padding: 15px 0;
    }

    .job-detail h2 {
      padding: 20px 0;
      font-size: 24px;
    }
    .job-detail .list-info-job {
      width: 100%;
      padding: 0 10px;
    }
    .job-detail .list-info-job li {
      font-size: 14px;
      padding-bottom: 0;
    }
    .main-infojob {
      padding: 20px;
    }
    .main-infojob h3 {
      font-size: 24px;
      line-height: 30px;
      padding: 0;
      padding-bottom: 10px;
    }
    .job-detail .copy {
      margin-top: 20px;
      padding: 20px;

      .btn-bg {
        width: 100%;
        line-height: 25px;
        padding: 13px 0;
        letter-spacing: 0;
      }
    }
    .job-detail .copy div {
      padding-bottom: 10px;
    }
    .job-detail .copy h3 {
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 10px;
    }
    .job-detail .copy p {
      padding-bottom: 5px;
      font-size: 14px;
    }
    .job-detail {
      padding-bottom: 20px;
    }
    .popup {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      margin: 0 !important;
      overflow: auto;

      .left {
        display: none;
      }
      .right {
        width: 100%;
        margin-right: 0;
        padding: 50px 10px 0 10px;
      }
    } 
    .popup .fancy-select {
      width: 50%;
      padding-right: 5px;
    }
    .popup .fancy-select:nth-child(2) {
      padding-right: 0;
      padding-left: 5px;
    }
    .popup ul li {
      padding: 5px 0;
    }

  
  .mall-wrap {
    padding-top: 20px;
  }
  .mall-wrap ul {
    padding-top: 20px;
  }
  .mall-wrap ul li {
    border-bottom: 1px #a5a5a5 solid;
    padding-bottom: 20px;

    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
  #pHome .bx-controls-direction {
    display: none;
  }
  .banner-page div {
    height: 120px;
  }
  .banner-page h2 {
    font-size: 28px;
  }
  .why-ipp {
    height: auto;
  }
  .why-ipp .copy {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    padding: 30px 10px;
}
.why-ipp .copy h3 {
    font-size: 24px;
    line-height: 31px;
    padding-bottom: 10px;
}

.testimo-wrap .testimo-item .img,
.testimo-wrap .testimo-item .copy {
  padding: 0 !important;
}
.faq-wrap ul {
  padding-top: 20px;
}
.faq-wrap ul li {
  width: 50%;
}
.faq-wrap ul li .copy a {
  padding-bottom: 10px;
}
.faq-wrap {
  padding-bottom: 20px;
}
.faq-detail-wrap .content {
  width: 100%;
  padding-left: 0;
}
.faq-detail-wrap .cate-faq {
  position: relative;
  width: 100%;
}
.banner-faq .copy {
  position: relative;
  top: 0;
  -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    padding-top: 10px;
}
.banner-faq h2 {
  padding-bottom: 20px;
  color: $blue-bg;
}
.banner-faq .copy div {
  width: 100%;
  padding-right: 100px;
  position: relative;
}
.banner-faq .copy input[type='text'] {
  width: 100%;
  padding: 8px 10px 9px 10px;
}
.android .banner-faq .copy input[type='text'] {
  padding-top: 10px;
}
 .banner-faq .copy input[type='submit'] {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  padding: 0;
  border-radius: 0 !important;
}
.faq-detail-wrap h3 span {
  padding: 0 40px 0 10px;
}
.faq-detail-wrap h3:after {
  right: 10px;
}
.faq-detail-wrap h3,
.faq-detail-wrap .cate-faq a {
  min-height: 1px;
  height: auto !important;
  padding: 10px 40px 10px 10px;
}
.faq-detail-wrap h3 span,
.faq-detail-wrap .cate-faq a span {
  position: relative;
  top: 0;
  -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    padding-top: 10px;
    padding: 0;
    font-size: 15px;
    line-height: 20px;
}
.acc-faq .acc-item .ct-acc {
  padding: 10px;
}

  .newsletter-wrap input[type='text'] {
          width: 100%;
    }
    #pInvest .info-wrap {
    padding-bottom: 20px;
  }


  .contact-wrap .copy {
      width: 100%;
      padding-bottom: 10px;
    }
    .contact-wrap .ct-form {
      width: 100%;
      padding-left: 0px;
    }
    .contact-wrap .ct-form .btn-bg {
      margin-top: 10px;
    }
    .add-ct .map {
      width: 100%;
      height: 400px;
      padding-left: 0;
    }
    .add-ct .right-ct {
      width: 100%;
      padding-right: 0;
    }
    .contact-wrap .copy h3,
    .contact-wrap .ct-form h3 {
      font-size: 20px;
      padding-bottom: 10px;
    }
    .contact-wrap .copy p {
      padding-bottom: 10px;
    }
    .contact-wrap .ct-form li {
      margin-bottom: 10px;
    }
    .contact-wrap .ct-form .btn-bg {
      width: 100%;
    }
    .contact-wrap {
      padding-bottom: 20px;
    }
    .add-wrap {
      padding: 20px 10px 0 10px;
    }
    .add-ct {
      padding: 20px 0;
    }
    .add-ct .right-ct .copy {
      padding: 20px 0;
    }
    .add-ct .map {
      height: 300px;
    }
    #pNews .top-page {
      padding-top: 20px;
    }
    .houses-top-wrap .copy {
      right: 0;
      padding: 0 10px;
    }
    .houses-top-wrap .copy p {
      font-size: 14px;
    }
    // .grey-bg.tophouse,
    // .grey-bg.section-page {
    //   padding-top: 0;
    // }
    .grey-bg.tophouse .container,
    .grey-bg.section-page .container{
      padding: 0 10px;
    }
    .more-downtown img {
      display: none;
    }
    .item-news:hover .tt .ar-link {
        background: url(../images/ar-link-hv.png) no-repeat;
        background-size: 6px;
    }
    .link-ft h2 img {
      width: 100px;
    }
    .list-job-wrapp .list-job li {
      padding: 10px;
    }
    .list-job-wrapp .list-job li p,
    .list-job-wrapp .list-job li .tt {
      font-size: 14px;
      width: 100% !important;
      padding: 0 !important;
      margin-bottom: 3px;

      span {
        display: inline-block;
        font-weight: bold;
        color: #4c4c4c;
        font-family: 'playfair_displaybold';
        margin-right: 10px;
      }
    }
    .list-job-wrapp .list-job li .tt {
      font-size: 17px;

      span {
        font-size: 14px;
      }
    }
    .btn-filter {
       display: inline-block;
       padding: 10px;
       width: 100%;

       a {
        width: 100%;
        font-family: 'playfair_displaybold';
        font-size: 20px;
        color: #253a71;
        text-transform: none;
        display: inline-block;
        line-height: 50px;
        padding: 0 30px;
        min-width: 200px;
        height: 50px;
        text-align: center;
        letter-spacing: 1px;
        border: 1px #d0d0d0 solid;

        span {
          background: url(../images/icon-filter-d.png) center right no-repeat;
          background-size: 14px; 
          padding-right: 25px
        }
        &:hover {
          text-decoration: none;
        }
        &.active {
          span {
            background: url(../images/icon-filter-u.png) center right no-repeat;
            background-size: 14px; 
          }
        }
       }
    }

    .nopaddingjob .btn-filter {
      padding: 10px 0;
    } 
    .fancy-select .trigger {
      padding-left: 10px;
    }
    .android .fancy-select .trigger {
      padding-top: 17px;
    }

    .policy-wrap  {
      padding-top: 20px;
    }
    .policy-wrap .content ul {
      margin-left: 20px;

      li {
        margin-bottom: 8px;
      }
    }
    .faq-detail-wrap {
      padding: 20px 10px;
    }
    .info-wrap .right .item .img {
      display: block;
    }
    .popup ul li input[type='text'],
    .popup ul li textarea {
      padding-left: 10px;
    }
    .banner-top .copy p br {
      display: none;
    }
    .contact-wrap .ct-form input[type="text"],
    .contact-wrap .ct-form textarea {
      padding: 16px 10px;
    }
    .android .contact-wrap .ct-form input[type="text"] {
      padding-top: 18px;
    }

    .main-infojob .img {
      float: none;
       margin: 0 auto;;
      margin-bottom: 10px;
      width: 100px;
     
    }
    #popup_container,
    .success-alert {
      width: 100%;
    }
    .success-alert div a, .success-alert div span {
          padding: 0 11px;
      min-width: 91px;
    }
    .thumb-ct a {
          background: url(../images/ar-link-hv.png) right 5px no-repeat;
          background-size: 6px;
    }
    .contact-wrap .ct-form .checkbox {
      margin-right: 5px;
    }
    .showmb-tl {
      display: block !important;
    }
    .ar-down {
      display: none;
    }

    .success-alert {
      height: 100%;
      top: 0;
      left: 0;
      -ms-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0); 
        overflow: auto;
        // padding: 50px 0;

      //   .copy {
      //     position: relative;
      //     top: 0;
      // left: 0;
      // -ms-transform: translate(0, 0);
      //   -webkit-transform: translate(0, 0);
      //   transform: translate(0, 0); 
      //   }

    }

    .success-alert h3 {
      font-size: 30px;
    }
    .cap h2 {
      margin-bottom: 15px;
    }
    .video-wide {
      height: 300px;
    }
    .map-detail-wrap {
      padding-top: 20px;
    }

    
    .map-dt {
      height: 300px;
    }

    .popup-youtube {
      width: 100%;
      height: 100%;
    }
    .popup-youtube .btn-close {
      top: 5px;
      right: 5px;
    }
    .branb-fb-crs .left {
      width: 100%;
      padding: 0 5px;
    }
    .branb-fb-crs .right {
       width: 100%;
       padding: 0 5px;
    }
    .branb-fb-crs .left .item, .branb-fb-crs .right .item {
      padding: 5px;
    }
    .value-home {
      padding: 20px 10px;
    }
    .banner-top .copy {
      top: 44%;
    }
    .pretext {
      bottom: 10px;
      font-size: 16px;
    } 
    .media-news {
      text-align: center;

      span,em {
        width: 100%;
      }
    }
    .media-news {
      padding: 20px 10px 0 10px;
    }
    .media-news em img {
      margin-right: 20px;
      margin-bottom: 10px;
      height: 17px;
    }
    .newsdetail-wrap {
      padding-bottom: 20px;
    }
    .brand-home-wrap {
      padding: 20px 10px;
    }
    .brand-home {
      padding: 30px 10px;

      li {
        width: 33.3333%;
        margin-bottom: 20px;
      }
    }
    .brand-home li h3 {
      font-size: 20px;
      br {
        display: none;
      }
    }
    .brand-home li a {
      font-size: 12px;
    }
    .all-brand-wrap {
      padding-top: 20px;
    }
    .brand-content {
      padding: 20px 0;
    }
    .colum-5 li {
      width: 33.3333%;
    }
    .brand-content ul li a {
      font-size: 11px;
    }
    .brand-content h3 {
      font-size: 20px;
    }
    .branb-fb-crs .item {
        padding: 5px;
      }
      .branb-fb-crs {
        padding: 5px;
      }

      .playvideo div {
        width: 40px;
        height: 40px;
      }
      .playvideo p {
        font-size: 12px;
      }

      .branb-fb-crs .slick-prev {
        left: 5px;
      }
      .branb-fb-crs .slick-next {
        right: 5px;
      }

    .more-downtown {
      padding: 40px 0;
    }
    .brand-fb-wrap {
      padding-bottom: 10px;
      
    }
    .all-brand-wrap {
      .big-tt {
        br {
          display: none;
        }
      }
    }
    .viewpage-wrap {
      padding: 20px 0;
    }
    .viewpage-wrap.pad-top-none {
      padding-top: 20px !important;
    }

    .award-wrap {
      padding-top: 20px;
     }

     .award-banner-top .copy {
      padding: 0 10px;
      position: relative;
      top: auto;
      left: auto;
      -ms-transform: translateY(0);
      -webkit-transform: translateY(-0);
      transform: translateY(0);
      padding-top: 10px;

      h3 {
          color: $blue-bg;
        }
        p {
          color: #4c4c4c;
        }
     }
     .list-award li {
      width: 100%;
      padding-right: 0;
     }
     .award-ct {
      padding-bottom: 20px;

      .ct-btn {
        padding: 0 10px;
      }
    }
    .award-banner-top.banner-2 .copy {
      position: relative;
      top: auto;
      left: auto;
      -ms-transform: translateY(0);
      -webkit-transform: translateY(-0);
      transform: translateY(0);
      padding-top: 10px;
      padding: 10px 10px;
      width: 100%;

      h3 {
        font-size: 25px;

        br {
          display: none;
        }
      }
    }
    .list-award {
      padding: 10px 10px 0 10px;
      margin-bottom: 10px;
    }

    
}

 @media all and (max-width: 767px) and (orientation:portrait) {
      .news-wrap .list-news li {
        width: 100%;
      }

      .item-news .img img {
          max-width: 9999px;
          width: 100%;
      }
      .item-news .copy {
        height: auto !important;
      }
      .success-alert {
        background: url(../images/demo/bg-popup-mb.jpg) center left no-repeat;
        background-size: cover !important;
      }
      .success-alert .btn-ct a {
      width: 100%;
          margin: 0;
          margin-bottom: 10px;
      }
      .op-map .fancy-select {
        width: 100%;
        margin-bottom: 10px;
        padding: 0 !important;
      }
      .op-map .fancy-select .trigger {
        width: 100%;
      }
      .op-map a {
        width: 100%;
        margin-right: 0;
        text-align: left;
        padding: 5px 0 5px 30px;
      }
      .branb-fb-crs .item {
        width: 50%;
            padding: 5px;
      }
      .branb-fb-crs .item:first-child {
        width: 100%;
      }
      .branb-fb-crs {
        padding: 5px;
      }
      .colum-5 li,
      .colum-3 li  {
        width: 50%;
      }
      .brand-content ul li {
        height: auto !important;
      }
      .brand-home li {
        width: 50%;
      }
    }


     @media all and (max-width: 567px) and (orientation:landscape) {
      .success-alert .btn-ct a {
      width: 100%;
          margin: 0;
          margin-bottom: 10px;
      }
      
     }

@media screen and (max-width: 599px) {
  #pHousesDetail .banner-top .img {
    width: 100%;
  }
  .banner-top .copy {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    padding: 10px;
    -ms-transform: translate(0, 0);
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
  }
  .more-downtown .copy h3 {
    font-size: 18px;
  }
  .mind-wrap ul li {
    width: 100%;
    margin-bottom: 20px;
  }
  .mind-wrap ul li .img {
    margin-bottom: 10px;
  }
  .linetime-item .copy {
    padding-left: 180px !important;
  }
  .linetime-item .copy .img {
    width: 170px;
  }
  .img-mobile {
    display: inline-block;
    
  }
  .img-desktop {
    display: none;
  }
  .talents-home-wrapp .view-item .copy .btn-bd,
  .testimo-wrap .btn-bd {
    width: 100%;
  }
  .testimo-wrap .testimo-item .copy p {
    font-size: 15px;
    line-height: 20px;
  }
  .banner-news .copy .btn-bd {
    width: 100%;
  }
  .pretext {
    position: relative;
    width: 100%;
    padding: 0;
    right: auto;
    bottom: 0;
    padding-bottom: 10px;
  }


}
@media screen and (max-width: 413px) {
  .item-wrap-home ul li {
    padding: 0 10px;
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .sub-link-ft a {

  }
  .brand-wrap ul li {
    width: 100%;
  }
  
  .why-inves-wrapp ul li {
    width: 100%;
  }
  .why-inves-wrapp ul li .copy {
    height: auto !important;
  }
  .why-inves-wrapp ul li {
    margin-bottom: 10px;
  }
  .linetime-item .copy {
    padding-left: 0 !important;
  }
  .linetime-item .copy .img {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
  }
  .linetime-item {
    border-bottom: 1px #adadad solid;
    padding-bottom: 20px !important;
  }
  .popup ul li .btn-bd {
    width: 100%;
  }
  .popup ul li .btn-bg {
    width: 100%;
  }
  .popup .p-must {
    padding-bottom: 10px;
  }
  .faq-wrap ul li {
    width: 100%;
  }
  .item-wrap-home ul li .copy .btn-bd, {
    width: 100%;
  }
  .history-wrap-home .copy a,
  .ct-btn .btn-bd,
  .houses-item .copy .btn-bd,
  #pJobList .more-downtown .copy a,
  .more-downtown .copy a {
    width: 100%;
  }
  #pJobList .more-downtown .copy a {
    font-size: 13px;
  }
  .item-news .img img {
    max-width: 9999px;
    width: 100%;
  }
  .faq-wrap ul li .copy {
      text-align: left; 
    }

    .popup ul li .btn-bd span {
      width: 100% !important;
    }

    .success-alert div a, .success-alert div span {
      width: 100%;
      margin: 0;
      margin-bottom: 5px;
    }
}
//  Column mixin --------------------------------------------------------------
//
//  Create a % based column
//
//	@columns:	Number of columns that need to be spanned
//	@end: 		Mark the column as the end of a "row"
//  @break:		Point where a column starts floating and stretching
//	@context:	A context for @columns

@mixin column($columns, $end: false, $break: $default-column-breakpoint, $context: $total-columns) {

  display: inline-block;
  vertical-align: top;
  width: 100%;

  @include media-min($break) {
    $perc: (100 / $total-columns * $columns) * 1%;

    @if $end == false {
      float: left;
      margin-right: $default-gutter-width;
    } @else {
      float: right;
      clear: right;
      margin-right: 0;
    }

    width: $perc - ($default-gutter-width - ($default-gutter-width/($total-columns / $columns)));
  }
}

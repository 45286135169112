// Library imports ------------------------------------------------------------
//
// Links to common libraries are included below. Place the files at the root
// of the sass folder

// Global vars ----------------------------------------------------------------

$base-font-size: 16px;
$base-line-height: 1.5;
$max-container-width: 1120px;

// Greys ----------------------------------------------------------------------


$white: #fff;
$black: #000;
$red: #f00;
$alto: #dddddd;
$wildsand: #f6f6f6;
$jumbo: #88888c;
$jumbodark: #77777c;
$shiraz: #c00a3a;
$shirazdark: #ac0732;
$seashell: #F1F1F1;
$pink: #cc073c;


$base-bg-color: #fff;
$base-font-color: #000;
$placeholder-color: $black !default;
$border-color: #ccc;
$page-bg-color: #f6f6f6;


$border-style: 1px solid #dadada;
$content-padding: 30px 20px;

$max-z-index: 99999;

$z-level: (
  'modal':    9999,
  'overlay':  8000,
  'dropdown': 7000,
  'header':   5000,
  'footer':   5000,
  'default':  1,
  'below':    -1000
);


// Font-stack -----------------------------------------------------------------

/*@include font-face('Gardens', '../fonts/gbtb_light-webfont', normal, normal);
@include font-face('Gardens', '../fonts/gardens_bold-webfont', bold, normal );
@include font-face('Gardens-Bold-Alt', '../fonts/gardens_bold_alt-webfont', bold, normal);

@include font-face('Gardens-VN', '../fonts/texgyreadventor-regular-webfont', normal, normal);
@include font-face('Gardens-bold-VN', '../fonts/texgyreadventor-bold-webfont', bold, normal );*/

$em-base: 16px;

$font-family: 'Gardens', 'Helvetica Neue', Arial, sans-serif !default;
$font-family-bold-alt: 'Gardens-Bold-Alt', "Helvetica Neue", Arial, sans-serif !default;
$letter-space: normal;
$font-smoothing: subpixel-antialiased !default;
$font-family-jp: 'MS UI Gothic', 'MS Gothic', Arial, sans-serif;
$font-family-vn: 'Arial', Helvetica Neue, sans-serif;
$font-family-vn-bold: 'Arial', Helvetica Neue, sans-serif;

// Webkit tap highlight color for mobile
$webkit-tap-highlight-color: rgba(0, 0, 0, .13);

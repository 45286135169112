.contact-wrap {
	padding-bottom: 50px;
	.copy {
		width: 37%;
	    float: left;

	    h3 {
	    	font-size: 32px;
	    	color: $blue-bg;
	    	padding-bottom: 20px;
	    }

	    p {
	    	padding-bottom: 20px;

	    	span {
	    		font-family: 'sfu_universbold';
	    		color: $blue-bg;
	    	}

	    	a {
	    		color: $blue-bg;
	    		font-family: 'sfu_universbold';

	    	}
	    }
	}
	.ct-form {
		width: 63%;
	    float: right;
	    padding: 0 0 0 65px;

	    h3 {
	    	font-size: 32px;
	    	color: $blue-bg;
	    	padding-bottom: 20px;
	    }
	    li {
	    	margin-bottom: 20px;

	    	div {
	    		float: left;
	    	}
	    }
	    input[type="text"] {
	    	width: 100%;
		    color: #707070;
		    font-family: 'sfu_universlight';
		    font-size: 12px;
		    border-radius: 0;
		    background: #fff;
		    padding: 16px 22px;
		    border: 1px #dbdbdb solid;
	    }
	    textarea {
	    	width: 100%;
		    height: 130px;
		    color: #707070;
		    font-family: 'sfu_universlight';
		    font-size: 12px;
		    resize: none;
		    border-radius: 0;
		    background: #fff;
		    padding: 16px 22px;
		    border: 1px #dbdbdb solid;
	    }
	    .btn-bg {
	    	float: right;
	    	width: 172px;
	    	color: #fff;

	    	&:hover {
	    		color: $blue-bg;

	    		span {
	    			background: url(../images/ar-link.png) right no-repeat;
    					background-size: 4px;
	    		}
	    	}
	    }
	    label {
	    	cursor: pointer;
		    margin-top: 3px;
		    display: inline-block;
	    }
	    .checkbox {
	    	float: none;
	    	display: inline-block;
	    	vertical-align: middle;
	    }
	}
	
}

.checkbox {
  width: 27px;
  height: 27px;
  background: url(../images/checkbox.jpg) no-repeat;
  background-size: 27px;
  display: block;
  clear: left;
  float: left;
  margin-right: 10px;
}

.add-wrap {
	padding-top: 50px;
	.container {
		max-width: 1180px;
		// ul {
		// 	clear: both;
		// 	overflow: hidden;
		// 	margin-top: 50px;

		// 	li {
		// 		display: block;
		// 		width: 25%;
		// 		padding: 0 20px;
		// 		float: left;
		// 		margin-bottom: 50px;

		// 		img {
		// 			height: 20px;
		// 			margin-bottom: 10px;
		// 		}

		// 		p {
		// 			padding-bottom: 20px;
		// 		}
		// 	}
		// }


	}
}

.add-ct {
	padding: 50px 0;
	.map {
		float: right;
		width: 66.6666%;
		padding-left: 30px;
		height: 450px;
	}
	.right-ct {
		float: left;
		width: 33.3333%;
		padding-right: 30px;

		.fancy-select {
			width: 100%;
		}
		.fancy-select .trigger {
			background: #f0f0f0;
			width: 100%;
		}

		.copy {
			padding-top: 25px;

			.add-item {
				margin-bottom: 20px;
				border-bottom: 1px #cccccc solid;
				padding-bottom: 20px;

				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
				}
			}
			h3 {
				border-bottom: 1px #cccccc solid;
				padding-bottom: 17px;
				margin-bottom: 17px;
				img {
					height: 30px;
				}
			}
			p {
				padding-bottom: 5px;
				font-size: 14px;
				img {
					margin-right: 5px;
				}
				a {
					color: #4c4c4c;

					&:hover {
						text-decoration: none;
						color: $blue-bg;
					}
				}
			}
			.openmap {
				display: inline-block;
				background: url(../images/icon-openmap.png) left 0 no-repeat;
				height: 20px;
				padding-left: 27px;
				color: $blue-bg;
				line-height: 20px;
				text-decoration: underline;
				background-size: 20px;
				margin-top: 10px;
				float: left;

				&:hover {
						text-decoration: none;
					}
			}

			.get-there {
			    display: inline-block;
			    height: 20px;
			    background: url(../images/icon-road.png) left center no-repeat;
			    background-size: 20px;
			    padding-left: 30px;
			    color: $blue-bg;
			    text-decoration: underline;
			    float: right;
			    margin-top: 10px;

			    &:hover {
						text-decoration: none;
					}
			}
		}
	}
}

.thumb-ct {
	img {
		width: 100%;
		margin-bottom: 15px;
	}
	a {
		font-family: 'sfu_universbold';
		text-decoration: underline;
		background: url(../images/ar-link-hv.png) right center no-repeat;
		background-size: 6px;
		color: $blue-bg;
		display: inline-block;
		padding-right: 15px;

		&:hover {
			text-decoration: none;
		}
	}
}

#map {
	width: 100%;
	height: 100%;
	background: #f0f0f0;
}
.nolink {
	cursor: default;
	&:hover {
	text-decoration: none;
	color: inherit !important;
	}
}

.ar-down {
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -10px;
  animation: arrow-down 0.6s infinite alternate ease-in-out;
  border-bottom: 3px solid $blue-bg;
    border-right: 3px solid $blue-bg;
        display: block;
    height: 20px;
    transition: opacity 0.5s;
    width: 20px;
}

@keyframes ardown {
  from {
    bottom: -30px;
    opacity: 1;
  }
  to {
    bottom: -60px;
    opacity: 0;
  }
}

@-webkit-keyframes arrow-down {
  0% {
    -webkit-transform: translateY(-2px) rotate(45deg);
            transform: translateY(-2px) rotate(45deg); }
  100% {
    -webkit-transform: translateY(5px) rotate(45deg);
            transform: translateY(5px) rotate(45deg); } }

@keyframes arrow-down {
  0% {
    -webkit-transform: translateY(-2px) rotate(45deg);
            transform: translateY(-2px) rotate(45deg); }
  100% {
    -webkit-transform: translateY(5px) rotate(45deg);
            transform: translateY(5px) rotate(45deg); } }




.dgt_arrow {
  display: block;
  height: 20px;
  width: 20px;
  border: 11px solid transparent;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin: 0 auto;
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -10px;
}


.dgt_arrow:after,
.dgt_arrow:before {
  content: "";
  display: block;
  height: inherit;
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;
}


.dgt_arrow:after {
  border-bottom: 3px solid $blue-bg;
  border-right: 3px solid $blue-bg;
  top: 0;
  left: 0;
  opacity: 1;
  -webkit-animation: bottom-arrow 1.65s infinite;
          animation: bottom-arrow 1.65s infinite;
}


.dgt_arrow:before {
  top: 0;
  left: 0;
  border-bottom: 3px solid $blue-bg;
  border-right: 3px solid $blue-bg;
  -webkit-animation: top-arrow 1.65s infinite;
          animation: top-arrow 1.65s infinite;
}

@-webkit-keyframes bottom-arrow {
  0% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  45% {
    opacity: 0;
    -webkit-transform: translate(12px, 12px);
    transform: translate(12px, 12px);
  }
  46% {
    opacity: 0;
    -webkit-transform: translate(-16px, -16px);
    transform: translate(-16px, -16px);
  }
  90% {
    opacity: 1;
    -webkit-transform: translate(-6px, -6px);
    transform: translate(-6px, -6px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-6px, -6px);
    transform: translate(-6px, -6px);
  }
}
@keyframes bottom-arrow {
  0% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  45% {
    opacity: 0;
    -webkit-transform: translate(12px, 12px);
    transform: translate(12px, 12px);
  }
  46% {
    opacity: 0;
    -webkit-transform: translate(-16px, -16px);
    transform: translate(-16px, -16px);
  }
  90% {
    opacity: 1;
    -webkit-transform: translate(-6px, -6px);
    transform: translate(-6px, -6px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-6px, -6px);
    transform: translate(-6px, -6px);
  }
}
@-webkit-keyframes top-arrow {
  0% {
    -webkit-transform: translate(-6px, -6px);
    transform: translate(-6px, -6px);
  }
  35% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  90% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes top-arrow {
  0% {
    -webkit-transform: translate(-6px, -6px);
    transform: translate(-6px, -6px);
  }
  35% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  90% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}


.info-map {

	a {
		color: $blue-bg;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
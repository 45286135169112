/*
 * CSS framework Bica.
 */
/* Reset */
html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,code,del,dfn,em,img,q,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,caption,table,tbody,tfoot,thead{margin:0;padding:0;border:0;font-weight:inherit;font-style:inherit;font-size:100.01%;font-family:inherit;vertical-align:baseline;}

// Remove top shadow on inputs on ios devices
// input{-webkit-appearance: caret;-moz-appearance: caret;}
input,textarea{margin:0;padding:0 0 0 1px;font-weight:inherit;font-style:inherit;font-size:11px;font-family:arial;}
textarea{padding-left:1px; overflow:auto; vertical-align:top;}
// input[type="checkbox"],input[type="radio"]{box-sizing:border-box; padding:0;}
//input[type="search"]{-webkit-appearance:textfield; -moz-box-sizing:content-box; -webkit-box-sizing:content-box; box-sizing:content-box;}
//input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration{-webkit-appearance:none;}
button,input{line-height:normal;}
button,html input[type="button"],input[type="reset"],input[type="submit"]{ -webkit-appearance:button; cursor:pointer;}
button[disabled],input[disabled]{cursor:default;}
button::-moz-focus-inner,input::-moz-focus-inner{border:0; padding:0;}

table{border-collapse:collapse;border-spacing:0;}
table,td,th{/*border-style:solid;border-width:1px;border-color:#a5acb2*/}
caption,th,td{font-weight:normal;}
blockquote:before,blockquote:after,q:before,q:after{content:"";}
blockquote,q{quotes:"""";}
q:before,q:after{content:'';}
q{quotes: "\201C" "\201D" "\2018" "\2019";}
img{border:none;}
ul,li{list-style-type:none}
abbr[title]{border-bottom:1px dotted;}
dfn{font-style:italic;}
mark{background:#ff0; color:#000;}
code,kbd,pre,samp{font-family:monospace,serif; font-size:1em;}
pre{white-space:pre; white-space:pre-wrap; word-wrap: break-word;}
small{font-size:80%;}
sub,sup{font-size:75%; line-height:0; position:relative; vertical-align:baseline;}
sup{top:-0.5em;}
sub{bottom:-0.25em;}

/* HTML5 */
article,aside,details,figcaption,figure,footer,header,hgroup,nav,section,summary{display:block;}
audio,canvas,video{display:inline-block; *display:inline; *zoom:1;}
audio:not([controls]){display:none;}
[hidden]{display:none;}
img{border:0; -ms-interpolation-mode:bicubic; vertical-align:middle;}
// svg:not(:root){overflow:hidden;}
figure{margin:0;}
fieldset{border:1px solid #c0c0c0; margin:0 2px; padding:0.35em 0.625em 0.75em;}
legend{border:0; padding:0;}

/* Font */
html{ -webkit-text-size-adjust:100%; -ms-text-size-adjust: 100%;} /* Prevents iOS text size adjust after orientation change, without disabling user zoom. */
body{font-size:62.5%;font-family:Arial/*, Tahoma, Verdana*/;} /* 1em 10px */

/* Layout */
#hd,#ft{clear:both}
#bd:after{content:".";display:block;height:0;clear:both;visibility:hidden;}
/*#bd{zoom:1;}*/ /* validation */

/* Common elements */
.l{float:left}
.r{float:right}
.o{overflow:hidden}
.h{display:none}
.m0{margin:0}
.p0{padding:0}
.last{ margin-right:0; padding-right:0}
.cl,.clf{clear:both}
.clf{float:left}
hr{border:none;border-top:1px solid #ccc;height:1px;clear:both}
a		{text-decoration:none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);}
a:hover	{text-decoration:underline}
a:focus, a:active, a:hover, :focus,a,* {outline: 0;}
h1,h2,h3,h4,h5,h6{font-weight:700;} /* 62.5% standar size */
h1{font-size:1.8em;}
h2{font-size:1.3em;}
h3{font-size:1.2em;}
h4{font-size:1.1em;}
h5{font-size:1em;}
h6{font-size:0.9em;}
ul.ul-h{clear:both;}
ul.ul-h li{float:left}
ol li{list-style:decimal inside;}

/* easy clearing */
.clearfix:after{content:'';display:block;height:0;clear:both;visibility:hidden;font-size:0}
.clearfix{display:inline-block; position:relative;}
/*\*/
.clearfix{display:block;}
* html .clearfix             {zoom:1;} /* IE6 */
*:first-child+html .clearfix {zoom:1;} /* IE7 */


*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}


@media print {
    *{background:transparent !important; color:black !important; box-shadow:none !important; text-shadow:none !important; filter:none !important; -ms-filter:none !important;}
    a,a:visited{text-decoration:underline;}
    a[href]:after{content: " (" attr(href) ")";}
    abbr[title]:after{content: " (" attr(title) ")";}
    .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after{content:"";}
    pre,blockquote{border:#999 solid 1px; page-break-inside:avoid;}
    thead{display:table-header-group;}
    tr,img{page-break-inside: avoid;}
    img{max-width: 100% !important;}
    @page{margin: 0.5cm;}
    p,h2,h3{orphans:3; widows:3;}
    h2,h3{page-break-after:avoid;}
}

/* No script detect */
noscript div {
    background-color: #FD387D;
    bottom: 0;
    color: #FFFFFF;
    font-family: futurastdbook;
    line-height: 1;
    padding: 10px 0;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 9999;
}
noscript a {
	color: #fff;
}
noscript a:hover {
	text-decoration: none;
}


/* No support old IE version browser */
#iePopup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    display: none;
    font-family: Arial;
}
.ie-old #iePopup{ display: block;}
.ie-old body{ overflow: hidden}
#iePopup #jr_overlay{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    filter:alpha(opacity=95);
    opacity: 0.95;
    z-index: 1000000;
}
#iePopup #jr_wrap{
    width: 640px;
    height: 370px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -200px 0 0 -350px;
    padding: 15px 30px;
    z-index: 1000001;
    background: #f3f5f2;
}
#iePopup #jr_inner{
    font-size: 14px;
}
#iePopup #jr_inner p{
    margin-bottom: 14px;
    line-height: 1.1;
}
#iePopup #jr_header{
    font-family: Arial;
    font-weight: 400;
    font-size: 28px;
    margin-bottom: 10px;
    text-transform: uppercase;
    line-height: 1;
    margin-left: 0;
}
#iePopup #jr_firefox{}
#iePopup #jr_firefox a{  background: url(../images/ie/browser_firefox.gif) no-repeat left top    }
#iePopup #jr_chrome{}
#iePopup #jr_chrome a{   background: url(../images/ie/browser_chrome.gif) no-repeat left top    }
#iePopup #jr_msie{}
#iePopup #jr_msie a{     background: url(../images/ie/browser_msie.gif) no-repeat left top    }
#iePopup #jr_safari{}
#iePopup #jr_safari a{   background: url(../images/ie/browser_safari.gif) no-repeat left top    }
#iePopup #jr_opera{}
#iePopup #jr_opera a{    background: url(../images/ie/browser_opera.gif) no-repeat left top    }
#iePopup #jr_gcf{}
#iePopup #jr_gcf a{      background: url(../images/ie/browser_gcf.gif) no-repeat left top    }
#iePopup #jr_wrap ul{
    overflow: hidden;
    clear: both;
    margin-bottom: 10px;
}
#iePopup #jr_wrap li{
    float: left;
    width: 20%;
}
#iePopup #jr_wrap li a{
    display: block;
    width: 100px;
    padding: 105px 0 5px;
    margin: auto;
    text-align: center;
    line-height: 1;
    color: #000;
}
#iePopup #jr_close a{
    font-size: 16px;
    font-weight: 700;
    color: #000;
    text-decoration: underline;
    }
    #iePopup #jr_close a:hover{
        text-decoration: none;
    }


/*** 	Pattern 	 ***/

.flex-video {
    position: relative;
    height: 0;
    overflow: hidden;
}


.hardware-acceleration {
	/* these styles force the cpu to use hardware acceleration for animation, making the animation much more efficient! */
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*
#ANIMATION_NAME{
	-webkit-animation-name: flare;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function:linear;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-name: flare;
    -moz-animation-duration: 10s;
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function:linear;
    -moz-transform-origin: 50% 50%;
    -moz-animation-iteration-count: infinite;
    -o-animation-name: flare;
    -o-animation-duration: 10s;
    -o-animation-iteration-count: 1;
    -o-animation-timing-function:linear;
    -o-transform-origin: 50% 50%;
    -o-animation-iteration-count: infinite;
}
@-webkit-keyframes ANIMATION_NAME{
	0%{-webkit-transform: translate(1px,1px) rotate(0deg);}
	100%{-webkit-transform: translate(1px,1px) rotate(0deg);}
}
@-moz-keyframes ANIMATION_NAME{
	0%{-moz-transform: translate(1px,1px) rotate(0deg);}
	100%{-moz-transform: translate(1px,1px) rotate(0deg);}
}



@font-face {
    font-family: 'NiveaLight';
    src: url('../font/NiveaLight.eot');
    src: local('☺'), url('../font/NiveaLight.woff') format('woff'), url('../font/NiveaLight.ttf') format('truetype'), url('../font/NiveaLight.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}



*/


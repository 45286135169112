/**
 * BxSlider v4.1.2 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2014
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
  position: relative;
  margin: 0 auto 60px;
  padding: 0;
  *zoom: 1;
  height: 100%;
  max-width: 1600px;
}

/** THEME
===================================*/
.bx-wrapper .bx-viewport {
  background: $blue-bg;
  height: 100% !important;
  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0);
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: 30px;
  width: 100%;
}

/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url(images/bx_loader.gif) center center no-repeat #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}

/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  *zoom: 1;
  *display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: none;
  text-indent: -9999px;
  display: block;
  width: 9px;
  height: 9px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px #fff solid;

}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #fff;
}

/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  left: 35px;
}

.bx-wrapper .bx-prev:after {
  background: url(../images/prev-slider-home.png) no-repeat;
  background-size: 20px;
}
.bx-wrapper .bx-prev.white-color:after {
  background: url(../images/prev-slider-home-w.png) no-repeat;
  background-size: 20px;
}

.bx-wrapper .bx-next {
  right: 35px;
}

.bx-wrapper .bx-next:after {
  background: url(../images/next-slider-home.png) no-repeat;
  background-size: 20px;
}
.bx-wrapper .bx-next.white-color:after {
  background: url(../images/next-slider-home-w.png) no-repeat;
  background-size: 20px;
}

.bx-wrapper .bx-prev:hover {
  -ms-transform: rotate(0);
  /* IE 9 */
  -webkit-transform: rotate(0);
  /* Chrome, Safari, Opera */
  transform: rotate(0);
  // width: 60px;
  // height: 60px;
  //margin-top: -40px;
  //left: 20px;
}

.bx-wrapper .bx-prev:hover:after {
  -ms-transform: rotate(0);
  /* IE 9 */
  -webkit-transform: rotate(0);
  /* Chrome, Safari, Opera */
  transform: rotate(0);
}

.bx-wrapper .bx-next:hover {
  -ms-transform: rotate(0);
  /* IE 9 */
  -webkit-transform: rotate(0);
  /* Chrome, Safari, Opera */
  transform: rotate(0);
  // width: 60px;
  // height: 60px;
  //margin-top: -40px;
  //right: 20px;
}

.bx-wrapper .bx-next:hover:after {
  -ms-transform: rotate(0);
  /* IE 9 */
  -webkit-transform: rotate(0);
  /* Chrome, Safari, Opera */
  transform: rotate(0);
}

.bx-wrapper .bx-controls-direction a {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  outline: 0;
  width: 50px;
  height: 50px;
  text-indent: -9999px;
  z-index: 1;
  transition: all 0.3s ease;
  border: 1px $blue-bg solid;
  -ms-transform: rotate(45deg) scale(1);
  /* IE 9 */
  -webkit-transform: rotate(45deg) scale(1);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg) scale(1);
  background-size: cover !important;
  background-position: center center !important;

  &.white-color {
    border: 1px #fff solid;
  }
}

.bx-wrapper .bx-controls-direction a span {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: center center !important;
  background: red;
  opacity: 0;
  transition: all 0.3s ease;
}

.bx-wrapper .bx-controls-direction a:after {
  content: "";
  display: block;
  width: 20px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -4px 0 0 -10px;
  transition: all 0.3s ease;
  -ms-transform: rotate(-45deg);
  /* IE 9 */
  -webkit-transform: rotate(-45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(-45deg);
}

.bx-wrapper .bx-controls-direction a:hover {
  //border: none;
  -ms-transform: rotate(45deg) scale(1.3);
  /* IE 9 */
  -webkit-transform: rotate(45deg) scale(1.3);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg) scale(1.3);

  &:after {
    -ms-transform: rotate(-45deg) scale(1);
  /* IE 9 */
  -webkit-transform: rotate(-45deg) scale(1);
  /* Chrome, Safari, Opera */
  transform: rotate(-45deg) scale(1);
  }
}

.bx-wrapper .bx-controls-direction a:hover span {
  opacity: 1;
}

.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}

.bxslider {
  height: 100%;
}

.bxslider li {
  height: 100%;
  background-size: cover !important;
  background-position: right top !important;
  overflow: hidden;
}









/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
    padding: 0 18px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-arrow {
  display: block;
  width: 40px;
  height: 58px;
  background-position: top;
  background-repeat: no-repeat;
  position: absolute;
  top: 39%;
  // margin-top: -29px;
  border: none;
  text-indent: -9999px;
  cursor: pointer;

  &:hover {
    background-position: bottom;
  }

}

.slick-next {
  background: url(../images/button-next.png);
  right: -40px;
}
.slick-prev {
  background: url(../images/button-prev.png);
  left: -40px;
}


.fancy-select {
    position: relative;
    text-transform: uppercase;
    font-size: 13px;
    color: #46565D;
}

.fancy-select.disabled {
    opacity: 0.5;
}

.fancy-select select:focus + .trigger {
    //box-shadow: 0 0 0 2px #4B5468;
}

.fancy-select select:focus + .trigger.open {
    box-shadow: none;
}

.fancy-select .trigger {
    cursor: pointer;
    padding: 14px 24px 9px 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    border: 1px solid #c7c7c7;
    color: #4c4c4c;
    width: 340px;
    height: 50px;
    letter-spacing: 2px;

    transition: all 240ms ease-out;
    -webkit-transition: all 240ms ease-out;
    -moz-transition: all 240ms ease-out;
    -ms-transition: all 240ms ease-out;
    -o-transition: all 240ms ease-out;


    &:hover {
        border-color:#253a71;
        color: #253a71; 

        &:after {
           background: url(../images/drop-sellect-hv.png) no-repeat; 
           background-size: 8px;
        }  
    }

}

.disabled {
  
  .trigger {
    cursor: default;

    &:hover {
      border-color:#c7c7c7;
      color: #4c4c4c; 
    }
  }
}

.window .fancy-select .trigger {
  padding-top: 17px;
}

.fancy-select .trigger:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 5px;
    background: url(../images/drop-sellect.png) no-repeat;
    background-size: 8px;
    top: 22px;
    right: 22px;
}

.fancy-select .trigger.open {
    border-color: #253a71;
    color: #253a71; 
}

.fancy-select .trigger.open:after {
    background: url(../images/drop-sellect-hv.png) no-repeat;
    background-size: 8px;
}

.fancy-select .options {
    list-style: none;
    margin: 0;
    position: absolute;
    top: 40px;
    left: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    max-height: 200px;
    overflow: auto;
    background: #e8e8e8;
    min-width: 100%;

    transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
    -webkit-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
    -moz-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
    -ms-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
    -o-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
}

.fancy-select .options.open {
    visibility: visible;
    top: 50px;
    opacity: 1;

    /* have to use a non-visibility transition to prevent this iOS issue (bug?): */
    /*http://stackoverflow.com/questions/10736478/css-animation-visibility-visible-works-on-chrome-and-safari-but-not-on-ios*/
    transition: opacity 300ms ease-out, top 300ms ease-out;
    -webkit-transition: opacity 300ms ease-out, top 300ms ease-out;
    -moz-transition: opacity 300ms ease-out, top 300ms ease-out;
    -ms-transition: opacity 300ms ease-out, top 300ms ease-out;
    -o-transition: opacity 300ms ease-out, top 300ms ease-out;
}

.fancy-select .options.overflowing {
    top: auto;
    bottom: 40px;

    transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
    -webkit-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
    -moz-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
    -ms-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
    -o-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
}

.fancy-select .options.overflowing.open {
    top: auto;
    bottom: 50px;

    transition: opacity 300ms ease-out, bottom 300ms ease-out;
    -webkit-transition: opacity 300ms ease-out, bottom 300ms ease-out;
    -moz-transition: opacity 300ms ease-out, bottom 300ms ease-out;
    -ms-transition: opacity 300ms ease-out, bottom 300ms ease-out;
    -o-transition: opacity 300ms ease-out, bottom 300ms ease-out;
}

.fancy-select .options li {
    padding: 8px 12px;
    color: #959595;
    cursor: pointer;
    white-space: nowrap;

    transition: all 150ms ease-out;
    -webkit-transition: all 150ms ease-out;
    -moz-transition: all 150ms ease-out;
    -ms-transition: all 150ms ease-out;
    -o-transition: all 150ms ease-out;

}

.fancy-select .options .selected {
    background: #a7a7a7;
    color: #fff;
}

.fancy-select .options .hover {
    color: #fff;
    background: #a7a7a7; 
}




.animate-scroll .copy > * {
    opacity: 1 !important;
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
}







/*
    jAlert
 */
#popup_container {
  font-family: Arial, sans-serif;
  z-index: 10;
  font-size: 12px;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 300px !important;
  max-width: 600px !important;
  width: 600px;
  background: #fff;
  color: #000;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 1px $blue-bg solid;
}

#popup_title {
  display: none;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 1.75em;
  color: #666;
  border: solid 1px #FFF;
  border-bottom: solid 1px #999;
  cursor: default;
  padding: 0em;
  margin: 0em;
}

/* Disable title */
#popup_content {
  padding: 30px 10px;
  margin: 0;
}

#popup_message {
  text-align: center;
  padding: 10px 0 30px;
  font-size: 14px;
  font-family: 'playfair_displaybold';
  font-size: 15px;
  color: $blue-bg;
}

#popup_prompt {
  margin: 0;
}

#popup_panel {
  text-align: center;
  margin: 0;
}

#popup_panel input {
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 40px;
  padding: 0 0;
  color: #fff;
  font-family: 'sfu_universregular';
  position: relative;
  padding: 0 30px;
  min-width: 200px;
  height: 40px;
  text-align: center;
  letter-spacing: 1px;
  border: 1px $blue-bg solid;
  cursor: pointer;
  background:none;
  background: $blue-bg;
  border-radius: 0;
}



#popup_panel input:hover {
  text-decoration: none;
  color: #000;
  background: none;
  border: 1px #c3c3c3 solid;
}

#popup_overlay {
  background: #000;
  opacity: 0.3;
  z-index: 10 !important;
}





.animated {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  //opacity: 1;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
footer {
  text-align: center;
  font-size: 12px;
  color: #eec2a3;
  background: #112456;
  width: 100%;
  position: relative;
}

.link-ft {
  width: 100%;
  background: $blue-bg;
  padding: 30px 0 0 0;
}

.link-ft h2 {
  opacity: 0;
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0);
  transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.3s;
}

.link-ft h2 img {
  width: 160px;
}

.link-ft h2.animate-scroll {
  opacity: 1;
  webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.link-ft-wrap {
  max-width: 940px;
  margin: 0 auto;
  clear: both;
  overflow: hidden;
  padding-top: 30px;
}

.link-ft-wrap > div {
  float: left;
  text-align: left;
  width: 20%;
  padding-left: 40px;
  border-left: 1px #46527c solid;
  opacity: 0;
  -webkit-transform: translate3d(0, -50px, 0);
  transform: translate3d(0, -50px, 0);
  transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.link-ft-wrap > div:nth-child(2) {
  width: 35%;
  transition-delay: 0.2s;
}

.link-ft-wrap > div:nth-child(2) ul {
  width: 50%;
}

.link-ft-wrap > div:nth-child(1) {
  transition-delay: 0.3s;
  border: none;
  padding-left: 0;
}

.link-ft-wrap > div:nth-child(3) {
  transition-delay: 0.4s;

  h3:first-child {
    margin-bottom: 10px;
  }
}

.link-ft-wrap > div:nth-child(4) {

  h3:first-child {
    margin-bottom: 10px;
  }
}

.link-ft-wrap > div:nth-child(4) {
  transition-delay: 0.5s;
}


.link-ft-wrap > div:nth-child(4) ul {
  padding-right: 0;
}

.link-ft-wrap > div h3 {
  font-family: 'sfu_universbold';
  font-size: 14px;
  text-transform: uppercase;
  padding-bottom: 6px;

  a {
    color: #eec2a3;
    letter-spacing: 2px;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}

.link-ft-wrap > div ul {
  display: block;
  float: left;
  padding-right: 10px;
  margin-bottom: 13px;
}

.link-ft-wrap > div ul li a {
  font-size: 12px;
  color: #eec2a3;
  display: inline-block;
  padding: 2px 0;
  font-family: 'sfu_universlight';
}

.link-ft-wrap > div ul li a:hover {
  color: #fff;
  text-decoration: none;
}

.link-ft-wrap .animate-scroll {
  opacity: 1;
  webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.sub-link-ft {
  border-top: 1px #46527c solid;
  width: 100%;
  margin-top: 35px;
  padding: 15px 0;


}

// .sub-link-ft .js-open-popup {
//   display: inline-block;
//   font-size: 12px;
//   color: #eec2a3;
//   background: url(../images/icon-mail.png) left no-repeat;
//   padding-left: 20px;
//   cursor: pointer;
// }

// .sub-link-ft .js-open-popup:hover {
//   color: #fff;
// }

.sub-link-ft a {
  font-size: 12px;
  color: #eec2a3;
  display: inline-block;
  text-decoration: underline;
  padding: 2px 0;
  margin-right: 30px;
}

.sub-link-ft a:hover {
  color: #fff;
  text-decoration: none;
}

.sub-link-ft p {
  display: inline-block;
  margin-top: 12px;
  float: left;
}

.newsletter-wrap {
  background: #15285b;
  border: 1px #736c7c solid;
  display: inline-block;
  width: 516px;
  margin-left: 37px;
  float: right;

  input[type='text'] {
    display: inline-block;
    background: none;
    border: none;
    height: 45px;
    width: 460px; 
    background: url(../images/icon-mail.png) 10px no-repeat;
    background-size: 13px;
    padding-left: 30px;
    color: #eec2a3;
    font-size: 12px;
  }

  span {
    background: none;
    border: none;
    border-left: 1px #736c7c solid;
    display: inline-block;
    height: 45px;
    width: 50px;
    font-size: 12px;
    text-transform: uppercase;
    color: #eec2a3;
    cursor: pointer;
    vertical-align: top;
    line-height: 45px;

    &:hover {
      color: #fff;
    }
  }
}
.newsletter-wrap ::-webkit-input-placeholder {
  color: #eec2a3;
}

.newsletter-wrap :-moz-placeholder {
  /* Firefox 18- */
  color: #eec2a3;
}

.newsletter-wrap ::-moz-placeholder {
  /* Firefox 19+ */
  color: #eec2a3;
}

.newsletter-wrap :-ms-input-placeholder {
  color: #eec2a3;
}

.bottom-ft {
  padding: 15px 0;
}

.bottom-ft p {
  display: inline-block;
  float: left;
  margin-top: 3px;
  color: #8e797b;
}

.bottom-ft ul {
  display: block;
  float: right;
}

.bottom-ft ul li {
  display: block;
  float: left;
  margin-left: 10px;
}

.bottom-ft ul li a {
  opacity: 0.5;

  img {
    width: 21px;
  }
}

.bottom-ft ul li a:hover {
  opacity: 1;
}

.fixed_ft {
  position: fixed;
  left: 0;
  bottom: 0;
}
.ft-mb-wrap {
  clear: both;
  overflow: hidden;
}
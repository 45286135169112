// Bullet color mixin -------------------------------------------------------------
//
// Make a CSS bullet in a listing element with a different color than the color of the text, without the need of putting the text inside another element like span or strong
//
// $type:			The type of bullet, round or square, default is round
// $color:		The color of the bullet
// $top:			Top position of bullet
// $left:			Left position of bullet
// $size:			Size of the bullet, use font size

@mixin bullet-color($type: round, $color: #88888c, $top: -4px, $left: -15px, $size: 20px) {

	list-style-type: none;

	&:before {
		@if $type == round {
	  	content: '\2022';
	 	}
	  @if $type == square {
	  	content: '\25A0';
	 	}
	  display: block;
	  position: relative;
	  max-width: 0;
	  max-height: 0;
	  left: $left;
	  top: $top;
	  color: $color;
	  font-size: $size;
	}
}
.invest-boss {
	padding-top: 50px;
	.img {
		width: 50%;
		float: left;
		padding-right: 25px;

		img {
			width: 100%;
		}
	}
	.copy {
		width: 50%;
		float: right;
		padding-left: 25px;

		h3 {
			font-size: 32px;
			color: $blue-bg;
			padding-bottom: 10px;
		}

		p {
			font-style: italic;
			padding-bottom: 15px;
		}

		div {
			h4 {
				font-size: 24px;
				em {
					display: inline-block;
				    padding-left: 10px;
				    width: 13px;
				}
			}
			p {
				font-style: normal;
			}
		}
	}
}

.why-inves-wrapp {
	.container {
		max-width: 1220px;
	}

	ul {
		display: block;
		width: 100%;
		clear: both;
		overflow: hidden;
		margin-top: 50px;

		li {
			display: block;
			width: 33.333%;
			float: left;
			padding: 0 40px;
			margin-bottom: 50px;

			&>div {

			}
			.img {
				position: relative;
				margin-bottom: 15px;

				img {
					width: 100%;
				}
				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					background: rgba(0,0,0,.4);
					position: absolute;
					top: 0;
					left: 0;
				}

				h4 {
					width: 100%;
					font-size: 24px;
					color: #fff;
					display: block;
					position: absolute;
					top: 50%;
					left: 0;
					text-align: center;
					z-index: 2;
					padding: 0 10px;
					line-height: 30px;
					-ms-transform: translate(0, -50%);
				  -webkit-transform: translate(0, -50%);
				  transform: translate(0, -50%);
				  text-shadow: 0 0 5px #000;
				}
			}
		}
	}
}

#pInvest .more-downtown {
    border-top: none;
}
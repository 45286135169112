.banner-home {
  width: 100%;
  height: 100%;
  position: relative;
  background: #171d2d;
  max-height: 650px;
  overflow: hidden;
  opacity: 0;

  .dgt_arrow {
    bottom: 40px;

    &:after,
    &:before {
      border-bottom: 3px solid #fff;
      border-right: 3px solid #fff;
    }
  }
  .bx-wrapper .bx-pager {
    bottom: 60px;
  }
}

.banner-home .copy {
  max-width: 940px;
  position: relative;
  top: 50%;
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  margin: 0 auto;
  text-align: right;
  // clear: both;
  // overflow: hidden;
}

.banner-home .copy div {
  width: 490px;
  // float: right;
  display: inline-block;
  text-align: left;

  &.white-color {
    h3 {
      color: #fff;
      text-shadow: 0 0 40px #000;
    }

    a {
      color: #fff;
      border: 1px #fff solid;

      span {
        background: url(../images/ar-link-mb.png) right center no-repeat;
        background-size: 4px;
      }

      &:hover {
        border: 1px #fff solid;
      }

      &::before {
        box-shadow: 2px 2px 0 #fff inset;
      }
      
      &::after {
        box-shadow: -2px -2px 0 #fff inset;
      }
    }
  }
}

.banner-home .copy div > * {
  opacity: 0;
  -webkit-transform: translate3d(-100px, 0, 0);
  transform: translate3d(-100px, 0, 0);
  transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.banner-home .copy h2 {
  font-size: 40px;
  color: #fff;
  line-height: 50px;
  transition-delay: 0.05s;
  text-transform: uppercase;
  font-family: 'playfair_displaybold';
  letter-spacing: 1px;
  padding-bottom: 20px;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

.banner-home .copy h3 {
  font-size: 32px;
  font-family: 'playfair_displayregular';
  color: $blue-bg;
  transition-delay: 0.0;
 line-height: 40px;
 text-shadow: 0 0 40px #fff;

  strong {
    font-family: 'playfair_displaybold';
    font-size: 40px;
    font-weight: normal;
  }

  
}

.banner-home .copy p {
  font-size: 14px;
  padding-bottom: 30px;
  line-height: 17px;
  clear: both;
  color: $blue-bg;
  transition-delay: 0.15s;
  width: 310px;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

.banner-home .copy p span {
  max-width: 530px;
  display: inline-block;
}

.banner-home .copy a {
  transition-delay: 0.2s;
  color: $blue-bg;
  margin-top: 30px;
  border: 1px $blue-bg solid;
}

.banner-home .copy a svg rect {
  stroke: $blue-bg;
}

.copy.animation div > * {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}




.news-wrap-home,
.value-home,
.brand-home-wrap {
  width: 100%;
  padding: 37px 0 50px 0;
}

.news-wrap-home .container {
  max-width: 1170px;
}

.news-wrap-home h2 {
  // font-size: 48px;
}

.news-wrap-home ul {
  width: 100%;
  display: block;
  float: left;
  margin: 42px 0 20px 0;
}

.news-wrap-home ul li {
  width: 33.3333%;
  display: block;
  float: left;
  padding: 0 15px;
  text-align: center;
  opacity: 0;
}

.ct-btn {
  width: 100%;
  text-align: center;
}

.history-wrap-home {
  position: relative;
  padding: 50px 0;

  .img {
    width: 100%;
    float: left;
    opacity: 0;

    img {
      width: 100%;
    }
  }
  .copy {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 49%;
    right: 0;
    -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);

    h2 {
      // color: $blue-bg;
      // font-size: 35px;
      padding-bottom: 10px;
      margin-bottom: 15px;
      opacity: 0;
      -webkit-transform: translate3d(100px, 0, 0);
      transform: translate3d(100px, 0, 0);
      transition-delay: 0;
      transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-font-smoothing: antialiased;
    }
    p {
      padding-bottom: 36px;
      line-height: 25px;
      opacity: 0;
      -webkit-transform: translate3d(100px, 0, 0);
      transform: translate3d(100px, 0, 0);
      transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
      transition-delay: .2s;
      -webkit-font-smoothing: antialiased;
    }

    a {
      opacity: 0;
      -webkit-transform: translate3d(100px, 0, 0);
      transform: translate3d(100px, 0, 0);
      transition: all .6s cubic-bezier(0.19, 1, 0.22, 1);
      transition-delay: .4s;
      border: 1px #4e4e4e solid;
      position: relative;
      z-index: 3;
      -webkit-font-smoothing: antialiased;

      svg rect {
        stroke: #2f2f2d;
      }
    }
  }
}
.history-wrap-home .container:hover {
  a {
      &::before, &::after {
          height: 100%;
          width: 100%;
          transition: height $timing $easeInOutQuint, width $timing $timing $easeInOutQuint;
        }
      }
}
.link-bn {
   display: block;
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 2;  
  }


.item-wrap-home {
  padding: 0 0 70px 0;

  .container {
    max-width: 1220px;
  }

  ul {
    width: 100%;
    display: block;
    float: left;

    li {
      width: 50%;
      display: block;
      float: left;
      padding: 0 40px;
      opacity: 0;

      .img {
        width: 100%;
        overflow: hidden;
        margin-bottom: 20px;
        opacity: 1;
        transition: all 0.3s ease;
    //     -webkit-transition: all 0.3s ease;
    // -moz-transition: all 0.3s ease;
    // -o-transition: all 0.3s ease;
    // transition: all 0.3s ease;

        img {
          max-width: 100%;
          
        }
      }
      .copy {
        float: left;
        padding-right: 70px;

        h2 {
          color: $blue-bg;
          font-size: 36px;
          padding-bottom: 10px;
        }
        p {
          padding-bottom: 30px;
        }
        
      }

      &:hover {
        .img {
           opacity: 0.88;
        }
        p {
          color: #253a71;
        }
      }
    }
  }
}

.value-home {
  .copy {
    max-width: 760px;
    margin: 0 auto;
    text-align: center;

    h2 {
      margin-bottom: 25px;
    }
    p {
      line-height: 1.6;
    }
  }
}

// .brand-home {
//   padding: 130px 0;
//   .slick-slide {
//     text-align: center;
//     img {
//       max-width: 100%;
//       display: inline-block;
//     }
//   }

//   .slick-arrow {
//     top: 50% !important;
//     margin-top: -30px;
//   }
// }

.brand-home {
  padding: 60px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -30px;
    li {

      width: 16.666%;
      padding: 0 25px;
      text-align: center;

      h3 {
        color: $blue-bg;
        font-size: 24px;
        line-height: 30px;
        position: relative;
        display: inline-block;
        width: 100%;

        span {
          display: inline-block;
          width: 100%;
          position: relative;
          top: 50%;
          -ms-transform: translate(0, -50%);
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
        }
      }
      a {
        font-size: 14px;
        color: #4c4c4c;
        text-transform: uppercase;
        display: inline-block;
        width: 100%;

        &:hover {
          text-decoration: none;
          color: $blue-bg;
        }
      }

    }
}
.viewpage-wrap {
	padding-bottom: 10px;
}
.view-item-wrapp {
	padding-top: 40px;

	.view-item {
		width: 100%;
	    clear: both;
	    overflow: hidden;
	    position: relative;
	    margin-bottom: 50px;

	    .img {
		    width: 50%;
		    float: right;
		    text-align: center;
		    padding-left: 30px;

		    img {
		    	max-width: 100%;
		    }
	    }
	    .copy {
	    	width: 50%;
		    position: absolute;
		    top: 50%;
		    left: 0;
		    padding-right: 30px;
		    -ms-transform: translateY(-50%);
		    -webkit-transform: translateY(-50%);
		    transform: translateY(-50%);

		    h3 {
		    	font-size: 24px;
		    	border-left: 1px #b7b7b7 solid;
		    	padding-left: 20px;
		    	color: $blue-bg;
		    	line-height: 35px;
		    }
		    p {
		    	line-height: 24px;
		    }
	    }
	    &:nth-child(2n) {
	    	.img {
	    		float: left;
	    		padding-left: 0;
	    		padding-right: 30px;
	    	}
	    	.copy {
	    		left: auto;
	    		right: 0;
	    		padding-right: 0;
	    		padding-left: 30px;
	    	}
	    }
	}
}

#pIppImpact {
	// .view-item:nth-child(2) {
	// 	.img {
	// 		width: 39%;
	// 	}
	// 	.copy {
	// 		width: 61%;
	// 	}
	// }
	.view-item {
		.copy {

			h3 {
				padding-left: 0;
				border:none;
				padding-bottom: 15px;
				font-size: 37px;
			}
		}
	}
	.mind-wrap ul li p {
		font-family: 'playfair_displayregular';
        font-size: 18px;
	}
	
}

.value-wrap {
	h2 {
		margin-bottom: 42px;
	}

	ul {
		display: block;
		width: 100%;

		li {
			float: left;
			width: 50%;
			padding-right: 55px;
			min-height: 120px;
			margin-bottom: 40px;

			.copy {
				padding-left: 125px;
				position: relative;

				img {
					display: block;
					position: absolute;
					left: 0;
					top: 7px;
					// -ms-transform: translateY(-50%);
				 //    -webkit-transform: translateY(-50%);
				 //    transform: translateY(-50%);
				    width: 93px;
				}

				h3 {
					color: $blue-bg;
					font-size: 24px;
					padding-bottom: 10px;
				}
				p {
					line-height: 24px;
				}
			}

			&:nth-child(2n) {
				padding-right: 0;
				padding-left: 55px;
			}
		}
	}
}

.mind-wrap {
	padding-bottom: 60px;

	.container {
		max-width: 1180px;
	}

	ul {
		width: 100%;
		display: block;
		margin-top: 40px;

		li {
			width: 33.3333%;
			float: left;
			padding: 0 20px;

			.img {
				margin-bottom: 17px;
				background-size: cover !important;

				img {
					width: 100%;
				}
			}
			h3 {
				color: $blue-bg;
				font-size: 24px;
				padding-bottom: 10px;
			}
			p {
				line-height: 24px;
			}
		}
	}
}
.ovl {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
}

.popup {
  width: 1010px;
  height: 640px;
  position: fixed;
  background: #fff;
  z-index: 10;
  display: none;
  top: 50%;
  left: 50%;
  margin: -320px 0 0 -505px;

  .left {
    width: 450px;
    height: 100%;
    float: left;
    background-size: cover !important;
  }
  .right {
    width: 450px;
    height: 100%;
    float: right;
    margin-right: 55px;
    padding-top: 54px;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #4c4c4c;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #4c4c4c;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #4c4c4c;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #4c4c4c;
  }
}

.popup h3 {
  font-size: 25px;
  color: $blue-bg;
}
.popup h4  {
  font-size: 22px;
}

.popup h3 span {
  font-family: 'sfu_universbold';
}

.popup ul {
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
}

.popup ul li {
  width: 100%;
  display: block;
  float: left;
  padding: 5px 0;

  .cap-pu {
    padding-top: 0;
    color: #4c4c4c;
  }
}

.popup ul li p {
  font-size: 15px;
}

.popup ul li input[type='text'] {
  display: block;
  background: none;
  font-family: 'sfu_universlight';
  border: 1px solid #c7c7c7;
  color: #4c4c4c;
  width: 100%;
  height: 50px;
  font-size: 13px;
  padding: 8px 24px 9px 30px;
  border-radius: 0;
}

.popup ul li input[type='text']:hover, .popup ul li input[type='text']:focus {
  border-color: $blue-bg;
  color: $blue-bg;
}
.popup ul li textarea {
  display: block;
  background: none;
  font-family: 'sfu_universlight';
  border: 1px solid #c7c7c7;
  color: #4c4c4c;
  width: 100%;
  height: 50px;
  font-size: 13px;
  padding: 8px 24px 9px 30px;
  border-radius: 0;
  resize: none;
  height: 90px;
}

.popup ul li textarea:hover, .popup ul li textarea:focus {
  border-color: $blue-bg;
  color: $blue-bg;
}

.popup ul li .btn-bd {
  background: #ebebeb;
  height: 50px;
  line-height: 50px;
  font-size: 12px;
  color: #959595;
  padding: 0 12px;
  margin-bottom: 12px;
  float: left;
  min-width: 175px;

  &.btn-letter {
    float: right;
    span {
      line-height: 15px;
      position: relative;
      top: 6px;
      white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
    overflow: hidden;
    width: 221px;
    }
    em {
      font-size: 11px;
      text-transform: none;
    }
  }
  &.btn-file {
    span {
      white-space: nowrap; /* Don't forget this one */
text-overflow: ellipsis;
overflow: hidden;
    width: 169px;
    }
  }
}
.en ul li .btn-bd.btn-file span {
  width: 145px;
}
.en .popup ul li .btn-bd {

}
.popup ul li .btn-bd svg rect {
  fill: none;
  stroke: #cecece;
}

.popup ul li .btn-bd:hover svg rect {
  stroke: $blue-bg;
  stroke-dasharray: 50, 380;
  stroke-dashoffset: 150;
}

.hasfile {
  top: 3px !important;
}

.popup ul li .btn-bd span {
  display: inline-block;
  background: url(../images/icon-upload.png) left center no-repeat;
  padding-left: 26px;
}

.popup ul li .btn-bg {
  width: 150px;
  height: 50px;
  line-height: 50px;
  border-radius: 0;
  float: right;
}

.text-file {
  color: #919191;
}

.btn-close {
  display: block;
  width: 41px;
  height: 41px;
  opacity: 0.4;
  background: url(../images/btn-close.png) no-repeat;
  position: absolute;
  top: 17px;
  right: 17px;
  transition: all 0.3s ease-Out;
}

.btn-close:hover {
  opacity: 1;
  -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
    transform: rotate(90deg);
}

#apply-cv {
  margin-top: -318px;
}

#apply-cv .cap-pu {
  padding-bottom: 7px;
}

#newsletter .cap-pu {
  padding-bottom: 10px;
  color: #4c4c4c;
}

// #newsletter h3 {
//   font-size: 55px;
//   font-family: 'playfair_displaybold';
// }

#newsletter h3 span {
  font-size: 18px;
  font-family: 'sfu_universlight';
  line-height: 20px;
  display: inline-block;
}
.popup {
  .fancy-select {
    width: 210px;
    float: left;
    .trigger  {
      width: 100%;
    }

    &:nth-child(2) {
      float: right;
    }
  }

  .p-must {
    display: inline-block;
    float: left;
    font-style: italic;
    padding-top: 12px;
  }
}

.popup-youtube {
  width: 1000px;
  height: 550px;
  position: fixed;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    display: none;
    z-index: 10;

  .btn-close {
    top: -31px;
    right: -31px;
    background: url(../images/btn-close2.png) no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100%;
    opacity: 1;
    z-index: 3;
  }  
}

#video {
  width: 100%;
  height: 100%;
  background-size: contain !important;
  background-position: center !important;
  background-repeat: no-repeat;
}


.banner-brand-dt {

	img {
		width: 100%;
	}
}

#pHousesDetail {
	.mind-wrap ul li {
		text-align: center;

		.copy {
			padding: 0 50px;
		}
	}
}
.map-detail-wrap {
	padding-top: 45px;

	.more-downtown {
		margin: 0;

		h3 {
			font-size: 40px;
			padding: 0 136px 20px 136px;
		}
	}
}

.op-map {
    width: 100%;
    padding: 27px 0;
    text-align: center;

    .fancy-select {
	    margin-right: 30px;
	    display: inline-block;
	    vertical-align: middle;
	}
	.fancy-select .trigger {
		width: 260px;
		text-align: left;
	}

	a {
		display: inline-block;
		background: url(../images/demo/icon-marker.png) left center no-repeat; 
		background-size: 15px;
		padding-left: 25px;
		font-family: 'sfu_universbold';
		color: $blue-bg;
		text-decoration: underline;
		vertical-align: middle;
		margin-right: 30px;

		&:hover {
			text-decoration: none;
		}
		&.get-there {
			background: url(../images/icon-road.png) left center no-repeat; 
			background-size: 23px;
			padding-left: 30px;
		}
		&.disable {
			color: #afafaf;
			background: url(../images/icon-road-dis.png) left center no-repeat; 
			background-size: 23px;
			cursor: default;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.map-dt {
	width: 100%;
	height: 400px;
	background: $blue-bg ;
}

.video-wide {
	width: 100%;
	height: 588px;
	background-size: cover !important;
	background-position: center center !important;
	position: relative;
	cursor: pointer;

	// &:hover {
	// 	div {
	// 		background: url(../images/icon-play-hv.png) no-repeat; 
	// 		background-size: 100%;
	// 	}
	// 	p {
	// 		color: #fff;
	// 	}
	// }
	// &:after {
	// 	content: '';
	// 	width: 100%;
	// 	height: 100%;
	// 	background: rgba(0,0,0,.5); 
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	z-index: 1;
	// }

	.playvideo  {
		div {
			width: 110px;
			height: 110px;
		}
	}
}

.playvideo {
	width: 100%;
	cursor: pointer;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 2;
    transition: all 0.3s ease-Out;
    display: none;
	div {
		width: 70px;
		height: 70px;
		background: url(../images/icon-play.png) no-repeat; 
		background-size: 100%;
		margin-bottom: 11px;
		 transition: all 0.3s ease-Out;
		 display: inline-block;
	}

	p {
		font-size: 13px;
		color: #eec2a3;
		text-transform: uppercase;
		 transition: all 0.3s ease-Out;
	}

	
}


.brand-fb-wrap {
	padding-bottom: 80px;
	.container {
		max-width: 1158px;
	}
}

.branb-fb-crs {
	width: 100%;

	.slick-arrow {
		top: 50% !important;
		margin-top: -30px;
	}

	.item {
		padding: 9px;
		float: left;
		width: calc(25% - 3px);
		width: -webkit-calc(25% - 3px);
		width: -moz-calc(25% - 3px);
		width: calc(25% - 3px);
		cursor: pointer;

		&>div {
			position: relative;
			background-size: cover !important;
			transition: all 0.3s ease-Out;

			&:hover {
				opacity: 0.8;
			}
		}
		.playvideo {

				div {
					margin-bottom: 5px;
				}
			}

		img {
			width: 100%;
		}

		&:first-child {
			width: calc(50% + 5px);
			width: -webkit-calc(50% + 5px);
			width: -moz-calc(50% + 5px);
			width: calc(50% + 5px);

			.playvideo {

				div {
					width: 103px;
					height: 103px;
					margin-bottom: 10px;
				}
			}
		}
	}

	.slick-slide {
		padding: 0;
	}
	// .left {
	// 	width: calc(50% + 5px);
	// 	width: -webkit-calc(50% + 5px);
	// 	width: -moz-calc(50% + 5px);
	// 	width: calc(50% + 5px);
	// 	.item {
	// 		& > div {
	// 			&:after {
	// 				content: '';
	// 				width: 100%;
	// 				height: 100%;
	// 				background: rgba(0,0,0,.5); 
	// 				position: absolute;
	// 				top: 0;
	// 				left: 0;
	// 				z-index: 1;
	// 			}

	// 			&:hover {
	// 				div {
	// 					background: url(../images/icon-play-hv.png) no-repeat; 
	// 					background-size: 100%;
	// 				}
	// 				p {
	// 					color: #fff;
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	// .right {
	// 	width: calc(50% - 5px);
	// 	width: -webkit-calc(50% - 5px);
	// 	width: -moz-calc(50% - 5px);
	// 	width: calc(50% - 5px);
	// 	.item {
	// 		width: 50%;

			
	// 	}
	// }
}

.hasvideo {
	&:after {
		content: '';
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.5); 
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		transition: all 0.3s ease-Out;
	}

	&:hover {
		&:after {
			background: rgba(0,0,0,.3); 
		}
		
		p {
			color: #fff;
		}
		.playvideo {
			div {
				background: url(../images/icon-play-hv.png) no-repeat; 
				background-size: 100%;
			}
		}
	}
	.playvideo {
		display: block;

		
	}
}


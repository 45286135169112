.banner-faq {
	margin-top: 20px;

	img {
		width: 100%;
	}

	.copy {
		text-align: center;
		position: absolute;
		top: 50%;
		left: 0;
		-ms-transform: translate(0, -50%);
	    -webkit-transform: translate(0, -50%);
	    transform: translate(0, -50%);
	    width: 100%;

	    div {
	    	width: 730px;
	    	margin: 0 auto;
	    }

	    input[type='text'] {
		  display: inline-block;
		  float: left;
		  background: #fff;
		  font-family: 'sfu_universlight';
		  border: 1px solid #c7c7c7;
		  color: #4c4c4c;
		  width: 585px;
		  height: 50px;
		  font-size: 16px;
		  padding: 8px 24px 9px 20px;
		  border-radius: 0;
		}
		input[type='submit'] {
			float: left;
			height: 50px;
			line-height: 50px;
			color: #fff;
			letter-spacing: 1px;

			&:hover {
				background: #000f37;
				border-color: #000f37;
			}
		}
	}

	h2 {
		color: #fff;
		background: none;
		padding-bottom: 40px;
	}
}

.faq-wrap {
	padding-bottom: 50px;

	.container {
		max-width: 1170px;
	}

	ul {
		padding-top: 50px;
		li {
			display: block;
			float: left;width: 33.333%;
			padding: 0 15px;
			position: relative;
			margin-bottom: 30px;
			opacity: 0;
			.img {
				position: relative;

				a {
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background: rgba(27,40,75,0.45); 
					transition: all 0.3s ease-Out;

					span {
						display: block;
					    width: 100%;
					    padding: 0 10px;
					    position: absolute;
					    top: 50%;
					    right: 0;
					    -ms-transform: translate(0, -50%);
					    -webkit-transform: translate(0, -50%);
					    transform: translate(0, -50%);
					    font-family: 'playfair_displaybold';
					    line-height: 27px;
					    font-size: 28px;
					    text-align: center;
					    color: #fff;
					}
				}
			}
			img {
				width: 100%;
				
			}
			.copy {
				text-align: center;
			    background: #fff;
			    min-height: 90px;
			    position: relative;
			    transition: all 0.3s ease-Out;
			    padding: 30px 30px 30px 30px;

			    a{
			    	display: block;
				    width: 100%;
				    padding: 0 10px;
				    font-size: 14px;
				    color: #383838;
				    padding-bottom: 21px;

				    &:hover {
				    	text-decoration: none;
				    	color: $blue-bg;
				    }
			    }
			    .vm {
			    	font-family: 'playfair_displaybold';
			    	font-size: 18px;
			    	color: $blue-bg;
			    	padding-bottom: 0;

			    	&:hover {
			    		color: $blue-bg;
			    		text-decoration: none;
			    		color: #000;
			    	}
			    }
			}

			&:hover {
				img {
					opacity: 0.88;
				}
				.img {
					a {
						background: rgba(27,40,75,0.6); 
					}
				}
				// .copy {
				// 	background: $blue-bg;
				// 	p {
				// 		color: #fff;
				// 	}
				// }
			}
			// a {
			// 	display: block;
			// 	width: 100%;
			// 	height: 100%;
			// 	position: absolute;
			// 	top: 0;
			// 	left: 0;
			// }
		}
	}
}

.faq-detail-wrap {
	padding-bottom: 50px;
	.content {
		padding-left: 390px;
		width: 100%;
		position: relative;
		min-height: 500px;
	}
	.cate-faq {
		width: 315px;
		position: absolute;
		top: 0;
		left: 0;
		letter-spacing: 0;

		a {
			display: block;
			min-height: 70px;
			width: 100%;
			border: 1px #b3b3b3 solid;
			margin-bottom: 14px;
			
			position: relative;
			transition: all 0.3s ease-Out;

			span {
				position: absolute;
				width: 100%;
				    top: 50%;
				    left: 0;
				    -ms-transform: translate(0, -50%);
				    -webkit-transform: translate(0, -50%);
				    transform: translate(0, -50%);
				    font-family: 'playfair_displaybold';
				    line-height: 23px;
				    font-size: 18px;
				    color: #4c4c4c;
				    padding: 0 30px;
			}
			&:hover,
			&.active {
				background: $blue-bg;
				span {
					color: #fff;
				}
			}
		}
	}

	h2 {
		font-size: 24px;
		text-transform: uppercase;
		padding-bottom: 20px;
	}



	h3 {
		display: block;
		min-height: 81px;
		width: 100%;
		border: 1px #b3b3b3 solid;
		background: #fff;
		position: relative;
		transition: all 0.3s ease-Out;
		cursor: pointer;

		&:after {
			content: '';
			display: block;
			width: 21px;
			height: 12px;
			background: url(../images/ar-acc.png) no-repeat;
			position: absolute;
			right: 40px;
			top: 50%;
			 -ms-transform: translate(0, -50%);
			    -webkit-transform: translate(0, -50%);
			    transform: translate(0, -50%);

		}
		

		span {
			position: absolute;
			width: 100%;
			    top: 50%;
			    left: 0;
			    -ms-transform: translate(0, -50%);
			    -webkit-transform: translate(0, -50%);
			    transform: translate(0, -50%);
			    font-family: 'playfair_displaybold';
			    line-height: 23px;
			    font-size: 18px;
			    color: #4c4c4c;
			    padding: 0 60px 0 30px;
		}
		&:hover{
			background: $blue-bg;
			span {
				color: #fff;
			}
			&:after {
				background: url(../images/ar-acc-w.png) no-repeat;
			}
		}
		&.js-active{
			background: $blue-bg;
			span {
				color: #fff;
			}
			&:after {
				background: url(../images/ar-acc-hv.png) no-repeat;
			}

		}
	}
}
.acc-faq {
	.acc-item {
		margin-bottom: 25px;

		.ct-acc {
			padding: 22px;
			padding-bottom: 0;
			display: none;

			p {
				line-height: 24px;
				padding-bottom: 10px;

				a {
					color: $blue-bg;

					&:hover {
						text-decoration: underline;
					}
				}
				strong {
					font-weight: normal;
					font-family: 'sfu_universbold';
				}
			}
		}
		&:first-child {
			div {
				display: block;
			}
		}
	}	
}

.top-s-faq {
	padding-top: 40px;
	h2 {
		font-size: 36px;
		color: $blue-bg;
		padding-bottom: 30px;
	}
	div {
		width: 100%;
		position: relative;
		padding-right: 210px;
		margin-bottom: 13px;

		input[type='text'] {
		  display: inline-block;
		  float: left;
		  background: #fff;
		  font-family: 'sfu_universlight';
		  border: 1px solid #c7c7c7;
		  color: #4c4c4c;
		  width: 100%;
		  height: 50px;
		  font-size: 16px;
		  padding: 8px 24px 9px 20px;
		  border-radius: 0;
		}
		input[type='submit'] {
			height: 50px;
			line-height: 50px;
			color: #fff;
			letter-spacing: 1px;
			width: 210px;
			position: absolute;
			top: 0;
			right: 0;
			background-image: url(../images/icon-search-faq.png); 
			background-repeat: no-repeat;
			background-position: 40px center;

			&:hover {
				background: #000f37;
				border-color: #000f37;
				background-image: url(../images/icon-search-faq.png); 
			background-repeat: no-repeat;
			background-position: 40px center;

			}
		}
	}
	p{
		font-style: italic;

		span {
			font-style: normal;
			color: $blue-bg;
			font-family: 'sfu_universbold';
		}
	}
}
.padd-top {
	padding-top: 50px;
}

.policy-wrap {
	padding-top: 37px;
	.content {
		background-color: #fff;
		margin-top: 50px;
		padding: 70px 70px 50px 70px;

		div{
			padding-bottom: 30px;
		}
		span {
			text-transform: uppercase;
			font-family: 'sfu_universbold';
		}
		ul {
			margin-left: 40px;

			li {
				margin-bottom: 8px;
			}
		}

		a {
			color: $blue-bg;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
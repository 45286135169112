/**
 * Main CSS
 */

// General imports ------------------------------------------------------------
//
// These imports don't generate CSS, but make defined variables, functions,
// and mixins available for further usage
/* Locale Setting */
$locale: 'en';

@import 'base/config';
@import 'base/functions';
@import 'base/reset';
@import 'base/body';

/** Vendor **/
//@import 'vendor/jquery-ui';

/** Mixins */
@import 'mixin/all';


// Styles ---------------------------------------------------------------------
//@import 'module/layout';
// @import 'module/animate';
@import 'module/header';
@import 'module/footer';
@import 'module/plugin';
@import 'module/pageHome';
@import 'module/pageIntro';
@import 'module/pageHouses';
@import 'module/pageNews';
@import 'module/pageMilestone';
@import 'module/pageTalent';
@import 'module/pageIppway';
@import 'module/pageInvestment';
@import 'module/pageFaq';
@import 'module/pageContact';
@import 'module/pageBrandDetail';
@import 'module/pageSub';
@import 'module/popup';
@import 'module/mobile';

// IE fix ---------------------------------------------------------------------
@import 'ie';

// Print ----------------------------------------------------------------------
//
// Inline to avoid the request
@import 'module/print';

header {
  width: 100%;
  background: $blue-bg;
}

.hd-wrapp {
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
  height: 120px;
}

.menuscroll .hd-wrapp {
  height: 80px;
}

.hd-desktop {
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.hd-desktop h1 {
  text-align: center;
  display: block;
  position: relative;
  z-index: 3;
  position: absolute;
  top: 18px;
  left: 50%;
  margin-left: -80px;

  img {
    width: 160px;
  }
}

.hd-desktop .logo-scroll {
  opacity: 0;
  position: absolute;
  bottom: 27px;
  left: 0;
  margin-left: 0;
  transition: all 0.3s ease-Out;
  z-index: 1;
}

.hd-desktop .logo-scroll img {
  width: 130px;
}

.hd-desktop nav {
  display: block;
  width: 100%;
  text-align: center;
  position: relative;
  top: 62px;
}
.hd-desktop nav ul li:hover .subnav {
  opacity: 1;
  height: 240px;
  display: block;
}

.hd-desktop nav ul li .subnav {
  top: 120px;
}

.hd-desktop nav li:nth-child(1) {
  padding-left: 0;
}

.hd-desktop nav li:last-child {
  padding-right: 0;
  //float: right;
}

.hd-desktop nav li:nth-child(4) {
  //margin-right: 295px;
}
// .hd-desktop nav li:nth-child(5),
// .hd-desktop nav li:nth-child(6),
// .hd-desktop nav li:nth-child(7) {
//   padding: 0 30px;
// }

nav ul {
  display: inline-block;
  position: relative;
  z-index: 2;
  width: 100%;
  text-align: center;
}

nav ul li {
  display: inline-block;
  padding: 0 31px;
  position: relative;
  // float: left;
}

nav ul li a {
  font-size: 12px;
  font-family: 'sfu_universlight';
  text-transform: uppercase;
  display: block;
  padding: 20px 0;
  color: #eec2a3;
  position: relative;
  overflow: hidden;
}

nav ul li a:hover, nav ul li a.active {
  text-decoration: none;
  background: url(../images/nav-hover.png) center bottom no-repeat;
  // color: #fdde8c;
  
}
nav ul li a.active {
  font-family: 'sfu_universbold';
}

nav ul li a:hover:after, nav ul li a.active:after {
  bottom: 15px;
}

nav ul li a span {
  display: inline-block;
}

nav ul li a:after {
  content: '';
  transition: all 0.2s ease-Out;
  width: 100%;
  height: 1px;
  background: #eec2a3;
  position: absolute;
  left: 0;
  bottom: -2px;
  opacity: .8;
}

nav ul li.cr-menu a:after {
  bottom: 15px;
}

nav .slider-nav {
  width: 100%;
  height: 2px;
  background: #ddb373;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease-Out;
}

.subnav {
  width: 100%;
  height: 0;
  background: $blue-bg;
  position: fixed;
  top: 120px;
  left: 0;
  text-align: center;
  z-index: 2;
  border-top: 1px #46527c solid;
  overflow: hidden;
  opacity: 0;
}

.subnav .subnav-item {
  display: inline-block;
  text-align: left;
  padding-top: 35px;
  width: 815px;
  margin: 0 auto;

  ul,li {
    display: block;
    width: 211px;
  }
  .img {
    float: left;
    width: 295px;
    margin-left: 60px;
    position: relative;

    img {
      width: 100%;
    }

    span {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover !important;
    }
  }
}
#navHouses .sub-2 {
      width: 304px;
    left: 216px;
  ul {
    display: block;
    width: 100%;
  }
}
#navHouses .sub-2 li {
  width: 50%;
  float: left;
  padding-right: 10px;
}

.subnav .subnav-item h2 {
  color: #eec2a3;
  font-size: 18px;
  font-family: 'playfair_displaybold';
  text-transform: uppercase;
  padding-bottom: 12px;
  letter-spacing: 1px;
  display: inline-block;
  background: url(../images/ar-link-sub.png) right 8px no-repeat;
  background-size: 7px;
  padding-right: 20px;
}

.subnav .subnav-item .sub-1 {
  width: 460px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  float: left;
}

.subnav .subnav-item a {
  display: inline-block;
  width: auto;
  font-family: 'sfu_universlight';
  font-size: 13px;
  color: #eec2a3;
  padding: 2px 0;
  text-transform: none;
  padding-right: 20px;
  
}

.subnav .subnav-item a span {
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 1px #96141e solid;
}

.subnav .subnav-item a:hover {
  text-decoration: underline;
  // padding-left: 10px;
  background: url(../images/ar-link-sub.png) right 9px no-repeat;
  background-size: 6px;

}
.window .subnav .subnav-item a:hover {
  background: url(../images/ar-link-sub.png) right 6px no-repeat;
  background-size: 6px;
}

.subnav .subnav-item a:hover span {
  border-bottom: 1px #fdde8c solid;
}

// .subnav .subnav-item a:hover:after {
//   left: 0;
// }

.subnav .subnav-item a.active {
  text-decoration: underline;
  background: url(../images/ar-link-sub.png) right 9px no-repeat;
  background-size: 6px;
  font-family: 'sfu_universbold';
}
.window .subnav .subnav-item a.active {
  background: url(../images/ar-link-sub.png) right 6px no-repeat;
  background-size: 6px;
}
.subnav .subnav-item a.active span {
  border-bottom: 1px #fdde8c solid;
}
.subnav .subnav-item a.active-l {
  text-decoration: underline;
  background: url(../images/ar-link-sub.png) right 9px no-repeat;
  background-size: 6px;
  // font-family: 'sfu_universbold';
}
.window .subnav .subnav-item a.active-l {
  text-decoration: underline;
  background: url(../images/ar-link-sub.png) right 6px no-repeat;
  background-size: 6px;
  // font-family: 'sfu_universbold';
}
.subnav .subnav-item a.active-l span {
  border-bottom: 1px #fdde8c solid;
}

.sub-2 {
  width: 200px;
  position: absolute;
  top: 0px;
  left: 270px;
  display: none;

  h3 {
    color: #eec2a3;
    font-size: 14px;
    font-family: 'playfair_displaybold';
    padding-bottom: 12px;
    display: inline-block;
    background: url(../images/ar-link-sub.png) right 6px no-repeat;
    background-size: 6px;
    padding-right: 20px;
  }
  a {
    font-size: 10px !important;
    background: none !important; 
    text-transform: uppercase !important;
    padding-right: 5px !important;
  }
}
.window .sub-2 {
  top: 4px;
}
.window .sub-2 h3 {
  background: url(../images/ar-link-sub.png) right 5px no-repeat;
    background-size: 6px;
}

// .subnav .subnav-item a.active:after {
//   left: 0;
// }

// .subnav .subnav-item a:after {
//   content: "";
//   display: block;
//   position: absolute;
//   left: -4px;
//   top: 9px;
//   width: 4px;
//   height: 7px;
//   transition: all 0.3s ease-Out;
//   background: url(../images/arrow-submenu.png) no-repeat;
// }
// .macOs .subnav .subnav-item a:after {
//   top: 12px;
// }
// #navGroup .subnav-item {
//   padding-left: 148px;
// }
// #navHouses .subnav-item {
//   padding-left: 275px;
// }
// #navTalents .subnav-item {
//   padding-left: 527px;
// }

// .en #navGroup .subnav-item {
//   padding-left: 109px;
// }
// .en #navHouses .subnav-item {
//   padding-left: 253px;
// }
// .en #navTalents .subnav-item {
//   padding-left: 481px;
// }

// #navGroup .subnav-item {
//   padding-left: 77px;
// }
// #navHouses .subnav-item {
//   padding-left: 205px;
// }
// #navTalents .subnav-item {
//   padding-left: 457px;
// }

// .en #navGroup .subnav-item {
//   padding-left: 57px;
// }
// .en #navHouses .subnav-item {
//   padding-left: 202px;
// }
// .en #navTalents .subnav-item {
//   padding-left: 428px;
// }

.right-hd {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  transition: all 0.3s ease-Out;
}

.right-hd ul {
  display: block;
  float: left;
  margin-top: 13px;
}

.right-hd ul li {
  display: block;
  float: left;
  margin-right: 3px;
  position: relative;
}

.right-hd ul li a {
  font-family: 'sfu_universlight';
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  color: #eec2a3;
  line-height:20px;
  padding: 0 10px;
}

.right-hd ul li a:hover {
  text-decoration: none;
}

.right-hd ul li a.active {
  // background: url(../images/drop-lang.png) center right no-repeat;
  padding-right: 13px;
  text-decoration: underline;
}

.right-hd ul li a.hide-lang {
  //display: none;
  border-left: 1px #847a89 solid;
  // position: absolute;
  // width: 100%;
  // text-align: center;
  // top: 100%;
  // left: 0;
}

.right-hd ul li a.hide-lang:hover {
  // border: 1px #fff solid;
  // text-decoration: none;
  // color: #fff;
}

.ct-search {
  position: relative;
  float: left;
  z-index: 3
}

.ct-search input[type='button'] {
  display: block;
  float: left;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  background: #253a71 url(../images/icon-search-hv.png) center center no-repeat;
  transition: background-color 0.3s ease;
  position: relative;
  z-index: 2;
}

.ct-search input[type='button']:hover,
.ct-search input[type='button'].active {
  background: #000f37 url(../images/icon-search-hv.png) center center no-repeat;
}

.ct-search input[type='text'] {
  display: block;
  float: left;
  margin-left: 3px;
  background: none;
  font-family: 'sfu_universregular';
  color: #bcbcbc;
  width: 0;
  height: 50px;
  line-height: 22px;
  background: #fff;
  padding-left: 17px;
  border: none;
  position: absolute;
  top: 0;
  right: 33px;
  z-index: 1;
  font-size: 16px;
}

.ct-search ::-webkit-input-placeholder {
  color: #bcbcbc;
}

.ct-search :-moz-placeholder {
  /* Firefox 18- */
  color: #bcbcbc;
}

.ct-search ::-moz-placeholder {
  /* Firefox 19+ */
  color: #bcbcbc;
}

.ct-search :-ms-input-placeholder {
  color: #bcbcbc;
}
// .hasmenuscroll {

//   #navGroup .subnav-item {
//     padding-left: 282px;
//   }
//   #navHouses .subnav-item {
//     padding-left: 402px;
//   }
//   #navTalents .subnav-item {
//     padding-left: 647px;
//   }
// }

// .en .hasmenuscroll {

//   #navGroup .subnav-item {
//     padding-left: 243px;
//   }
//   #navHouses .subnav-item {
//     padding-left: 381px;
//   }
//   #navTalents .subnav-item {
//     padding-left: 600px;
//   }
// }

// .hasmenuscroll {

//   #navGroup .subnav-item {
//     padding-left: 239px;
//   }
//   #navHouses .subnav-item {
//     padding-left: 381px;
//   }
//   #navTalents .subnav-item {
//     padding-left: 598px;
//   }
// }

// .en .hasmenuscroll {

//     #navGroup .subnav-item {
//       padding-left: 239px;
//     }
//     #navHouses .subnav-item {
//       padding-left: 381px;
//     }
//     #navTalents .subnav-item {
//       padding-left: 598px;
//     }
// }

.menuscroll {
  height: 80px;
  position: fixed;

  
}


// .menuscroll .ct-search {
  .menuscroll h1 {
  display: none;
}

.menuscroll .logo-scroll {
  display: block;
  opacity: 1;
  transition: all 0.3s ease-Out;
  z-index: 3;
}

.menuscroll nav {
  display: block;
  text-align: center;
  margin-top: 2px;
  top: 0;
  width: auto;
}

.menuscroll nav ul li {
  // padding: 0 29px;
}

.menuscroll nav ul li:nth-child(4) {
  margin-right: 0;
}

.menuscroll nav ul li a {
  padding: 0 0;
  line-height: 78px;
}
.menuscroll nav ul li.cr-menu a:after {
    bottom: 23px;
  }

.menuscroll nav ul li a:hover, .menuscroll nav ul li a.active {
  text-decoration: none;

  &:after {
    bottom: 23px;
  }
}

.menuscroll .right-hd {
  // display: none;
      top: 12px;
      z-index: 3;
}

.lang-vn .hd-desktop nav li:nth-child(3) {
  margin-right: 207px;
}

.lang-vn .menuscroll nav ul li:nth-child(3) {
  margin-right: 0;
}

.lang-vn .hd-desktop nav li:nth-child(4) {
  margin-right: 32px;
}

.lang-vn .menuscroll nav li:nth-child(4) {
  margin-right: 0;
}

.lang-vn nav ul li {
  padding: 0 25px;
}

.opening {
  z-index: 4;
}
// Media-max mixin ------------------------------------------------------------
//
// Generates a max-width media query
//
// $value:	The breakpoint, in px
// $query:	What type of media this applies to, defaults to screen

@mixin media-max($value, $query: 'screen') {

  $value: em($value);
  @media #{$query} and (max-width: #{$value}) {
    @content;
  }
}

// Media-min mixin ------------------------------------------------------------
//
// Generates a min-width media query
//
// $value:	The breakpoint, in px
// $query:	What type of media this applies to, defaults to screen

@mixin media-min($value, $query: 'screen') {

  $value: em($value);
  @media #{$query} and (min-width: #{$value}) {
    @content;
  }
}

// Media-min-max mixin ------------------------------------------------------------
//
// Generates a inbetween-widths media query
//
// $valueMin:	The lower breakpoint, in px
// $valueMax:	The upper breakpoint, in px
// $query:		What type of media this applies to, defaults to screen

@mixin media-min-max($value-min, $value-max, $query: 'screen') {

  $value-min: em($value-min);
  $value-max: em($value-max);
  @media #{$query} and (min-width: #{$value-min}) and (max-width: #{$value-max}) {
    @content;
  }
}

// Highres mixin --------------------------------------------------------------
//
// Generates a media query for high-resolution displays

@mixin highres() {

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    @content
  }
}

.award-wrap {
	padding-top: 50px;
}
.award-ct {
	padding-bottom: 50px;
}
.award-banner-top {
    position: relative;

    img {
    	width: 100%;
    }
    .copy {
    	width: 100%;
	    position: absolute;
	    top: 50%;
	    left: 0;
	    text-align: center;
	    -ms-transform: translateY(-50%);
	    -webkit-transform: translateY(-50%);
	    transform: translateY(-50%);
	    padding: 0 200px;

	    h3 {
	    	font-size: 32px;
		    color: #fff;
		    padding-bottom: 20px;
	    }
	    p {
	    	font-size: 16px;
	    	color: #fff;
	    }
    }

    &.banner-2 {
    	.copy {
    		width: 70%;
    		padding: 0 50px;
    		left: auto;
    		right: 0;

    		h3 {
    			color: $blue-bg;
    		}
    		p {
    			color: #4c4c4c;
    		}
    	}
    }
}

.list-award {
	width: 100%;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
   	padding-top: 30px;
   	margin-bottom: 20px;

    li {
    	width: 50%;
    	padding: 0 20px 0 140px;
    	position: relative;
    	min-height: 100px;
    	margin-bottom: 10px;
    	cursor: pointer;

    	.img {
    		width: 120px;
    		position: absolute;
    		top: 0;
    		left: 0px;
    		overflow: hidden;
            max-height: 90px;

    		img {
    			width: 100%;
    		}

    		&:after {
    			content: "";
    			display: block;
    			width: 53px;
    			height: 53px;
    			border-radius: 200px;
    			background: #fff;
    			position: absolute;
    			top: 60px;
    			left: 89px;
    			transition: all 0.3s ease-Out;
    		}
    		&:before {
    			content: "";
    			display: block;
    			width: 53px;
    			height: 53px;
    			border-radius: 200px;
    			background: #fff;
    			position: absolute;
    			top: 60px;
    			left: 89px;
    			background: url(../images/demo/icon-award-b.png) 10px 10px no-repeat ;
    			background-size: 13px;
    			z-index: 2;
    			transition: all 0.3s ease-Out;
    		}
    	}

    	p {
    		padding-top: 10px;
    	}

    	&:hover {
    		.img {
    			&:after {
    				background: #3a4d7e;
    				top: 50%;
    				left: 50%;
    				right: auto;
    				-ms-transform: translate(-50%,-50%);
	                -webkit-transform: translate(-50%,-50%);
	                transform: translate(-50%,-50%);
    			}
    			&:before {
    				background: #3a4d7e;
    				top: 50%;
    				left: 50%;
    				right: auto;
    				-ms-transform: translate(-50%,-50%);
	                -webkit-transform: translate(-50%,-50%);
	                transform: translate(-50%,-50%);
	                background: url(../images/demo/icon-award.png) 16px 16px no-repeat ;
	                background-size: 21px;
    			}
    		}
    		p {
    			color: #3a4d7e;
    		}
    	}
    }
}
.about-wrapp {
	text-align: center;
	padding: 37px 0 50px 0;

}
.top-about {

	h2 {
		// font-size: 36px;
		// color: $blue-bg;
		// padding-bottom: 15px;
		margin-bottom: 15px;
	}

	p {
		display: inline-block;
		margin: 0 auto;
		max-width: 545px;
		line-height: 25px;
		padding: 0 10px;
		opacity: 0;
	}
}
.carousel {
	opacity: 1;
	position: relative;
}
.info-wrap {
	padding-top: 54px;

	.left {
		float: left;
		width: 33.33333333%;

		.item {

			a {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}

	}
	.right {
		float: right;
		width: 66.66666667%;
		padding-left: 30px;

		.item {
			position: relative;
			margin-bottom: 0;
			min-height: 500px;
			
			.img {
				width: 100%;
				text-align: right;
				height: 90%;
				background-size: cover !important;
				transition: all 0.3s ease-Out;
			}

			img {
				display: inline-block;
				width: 100%;
				display: none;
			}

			.copy {
				padding: 30px 35px 30px 35px;
				background: #fff;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
			}
			a {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
			}
		}
	}
	.item {
		background: #fff;
		position: relative;
		border: 1px #d2d2d2 solid;
		margin-bottom: 30px;
		text-align: left;
		opacity: 0;

		.img {
			overflow: hidden;
			img {
				width: 100%;
				transition: all 0.3s ease-Out;
			}
		}

		.copy {
			padding: 30px;
			position: relative;
			z-index: 2;

			br {
				display: none;
			}

			h3 {
				font-size: 24px;
				color: $blue-bg;
				padding-bottom: 5px;

				.ar-link {
			      display: inline-block;
			      width: 8px;
			      height: 14px;
			      vertical-align: middle;
			      background: url(../images/ar-link-hv.png) no-repeat;
			      background-size: 100%;
			      margin-left: 10px;
			      }
			}
			.btn-bd {
				margin-top: 30px;
			}
		}

		&:hover {
			.img {
				opacity: 0.88;
			}
	    	.img img {
	    		opacity: 0.88;
	    	}
	    	.copy {
	    		background: $blue-bg;
	    		h3 {
	    			color: #fff;

	    			.ar-link {
	    				background: url(../images/ar-link-w.png) no-repeat;
			      		background-size: 100%;
	    			}
	    		}
	    		p {
					color: #fff;
	    		}
	    	}
	    }
	}

	&:after {
		content: "";
	  clear: both;
	  display: block;
	} 
}

#pInvest .info-wrap {
	padding-bottom: 50px;
}

.number-wrap {
	border-top: 1px #e9ad82 solid;
	border-bottom: 1px #e9ad82 solid;
	padding: 37px 0 50px 0; 
	background: url(../images/demo/map.jpg) center center no-repeat; 
}
.the-number {
	width: 100%;
	clear: both;
	overflow: hidden;
	padding-top: 15px;

	ul {
		width: 100%;
		display: block;
		float: left;
		text-align: center;

		li {
			width: 32.33333%;
			display: inline-block;
			
			text-align: center;
			opacity: 0;

			// &:first-child {
			// 	text-align: left;
			// }
			// &:last-child {
			// 	text-align: right;
			// }

			// &:nth-child(2) {
			// 	em {
			// 		width: 160px;
			// 	}
			// }

			span {
				font-family: 'playfair_displaybold';
				font-size: 48px;
				color: #d09e7d;
				width: 100%;
				display: inline-block;
			}

			em {
				font-size: 18px;
				color: #d09e7d;
				display: inline-block;
				line-height: 20px;
				position: relative;
    			width: 100%;
				text-transform: uppercase;
			}
		}
	}
}


.bod-intro-wrap {
	padding: 35px 0 0 0;
	.container {
		max-width: 1180px;
	}

	ul {
		width: 100%;
		float: left;
		display: block;
		margin-top: 12px;

		li {
			width: 50%;
			float: left;
			display: block;
			padding: 0 20px;
			margin-top: 38px;
			margin-bottom: 15px;
			opacity: 0;

			img {
				margin-bottom: 10px;
			}

			img {
				width: 100%;
			}

			p {
				line-height: 24px;
			}
		}
	}
}

.info-founder {
	padding-bottom: 14px;

	h3 {
		font-size: 22px;
		color: $blue-bg;

		em {
			display: inline-block;
			padding-left: 10px;
			width: 13px;
		}
	}

	span {
		font-size: 12px;
  text-transform: uppercase;
  color: #8b8b8b;
	}
}

.leader-wrap {
	padding: 43px 0 70px 0;
	.container {
		max-width: 1178px;
	}
}

.leader {
	text-align: center;
	margin-top: 40px;
	opacity: 0;

	.img {
		
	}

	img {
		width: 100%;
	}
	.copy {
		padding: 20px 10px;
	}
	h3 {
		font-size: 21px;
		color: #2c2c2c;
		padding-bottom: 5px;
	}

	p {
		font-size: 10px;
  		text-transform: uppercase;
  		color: #8b8b8b;
	}

	.slick-slide:hover {
		.copy {
			background: $blue-bg;

			h3 {
				color: #fff;
			}
			p {
				color: #fff;
			}
		}
	}
}

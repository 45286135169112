.houses-top-wrap {
	padding-bottom: 50px;
	.img {
		float: left;
		padding: 50px 0 0 0;
		width: 58.33333333%;
		opacity: 0;

		img {
			width: 100%;
		}
	}

	.copy {
		width: 41.66666667%;
		position: absolute;
	    top: 47%;
	    right: 0;
	    -ms-transform: translate(0, -50%);
	    -webkit-transform: translate(0, -50%);
	    transform: translate(0, -50%);
	    padding-left: 27px;
	    text-align: center;

	    h3 {
	    	// font-size: 33px;
	    	// color: $blue-bg;
	    	//padding-bottom: 18px;
	    	margin-bottom: 15px;
	    }
	    p {
	    	font-size: 17px;
	    	opacity: 0;
	    }
	}

	.the-number ul {
		text-align: center;

		li {
			width: auto;
			float: none;
			margin: 0 30px;
			display: inline-block;

			em {
				width: auto;
			}
		}
	}
	
}

.list-houses-wrap {
	margin-left: 290px;
	padding: 55px 0 65px 0;

	&:after {
		content: "";
  		clear: both;
  		display: block;
	}
}

.side-menu {
	margin-left: -290px;
	width: 225px;
	background: #fff;
	float: left;

	ul {
		display: inline-block;
		width: 100%;
		li {
			display: inline-block;
			width: 100%;
			a {
				font-size: 13px;
				color: #000;
				padding: 15px 20px 13px 35px;
				display: inline-block;
				text-transform: uppercase;
				width: 100%;
				background: #fff;
				transition: all 0.3s ease-Out;

				&:hover,
				&.active {
					text-decoration: none;
					color: #fff;
					background: $blue-bg;
				}
			}
		}
	}
}



.list-houses {
	width: 100%;
	float: left;

	ul {
		display: inline-block;
		width: 100%;
		li {
			display: inline-block;
			width: 100%;
			margin-bottom: 54px;
			opacity: 0;
		}
	}
}

.houses-item {
	width: 100%;
	background: #fff;
	position: relative;

	.img {
	    width: 50%;
	    float: left;

	    img {
	      width: 100%;
	    }
	  }
	  .copy {
	    width: 50%;
	    float: left;
	    text-align: center;
	    padding-right: 8%;
	    position: absolute;
	    top: 50%;
	    right: 0;
	    -ms-transform: translate(0, -50%);
	  -webkit-transform: translate(0, -50%);
	  transform: translate(0, -50%);

	    .tt {
	      color: $blue-bg;
	      font-size: 35px;
	      display: inline-block;
	      padding-bottom: 15px;
	      font-weight: normal;
  font-family: 'playfair_displaybold';

  			img {
  				height: 30px;
  			}

		  &:hover {
		  	color: #284e96;
		  	text-decoration: none;
		  }
	    }
	    p {
	    	font-size: 17px;
	      padding-bottom: 30px;
	    }
	  }

	&:after {
		content: "";
  		clear: both;
  		display: block;
	}
}






#pHousesDetail .news-wrap-home {
	padding-top: 37px;
	padding-bottom: 70px;
}

.cap {
	text-align: center;
	max-width: 750px;
	padding: 40px 10px 0 10px;
	margin: 0 auto;
	line-height: 25px;

	hr {
		display: inline-block;
		width: 165px;
		margin-top: 60px;
		border-top: 1px solid #999999;
	}

	h2 {
		margin-bottom: 25px;
	}

	&.width-social {
		max-width: 1000px;
		text-align: left;
		ul {
			margin-left: 20px;
			margin-top: 0;
		}
		li {
			list-style-position: outside;
			list-style-type: disc;
			width: 100%;
			padding: 0;
		}
	}
}

.brand-wrap {
	padding-top: 50px;

	.small-tt {
		margin-bottom: 60px;
	}

	.container {
	    	max-width: 1170px;
	  	}
		ul {
	    width: 100%;
	    display: block;
	    clear: both;
	    overflow: hidden;
	    margin-top: 8px;
	    

	    li {
	      width: 25%;
	      display: block;
	      float: left;
	      padding: 0 15px;
	      margin-bottom: 50px;
	      margin-top: 30px;
	      opacity: 0;

	    }
	}
	.ct-btn {
		padding-bottom: 50px;
	}
}

.brand-item {
	position: relative;
	overflow: hidden;
	

	a {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}
	.img {
        width: 100%;
        overflow: hidden;
        transition: opacity 0.3s ease-Out;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(1.0, 1.0);
        background-size: cover !important;
        img {
          width: 100%;
          
        }
      }
      .copy {
      	text-align: center;
      	background: #fff;
      	min-height: 70px;
      	position: relative;
      	transition: all 0.3s ease-Out;
      	
        p {
          display: block;
          width: 100%;
          padding: 0 10px;
          position: absolute;
		    top: 50%;
		    right: 0;
		    -ms-transform: translate(0, -50%);
		  -webkit-transform: translate(0, -50%);
		  transform: translate(0, -50%);
		  font-family: 'playfair_displayregular';
		  font-size: 20px;
		  line-height: 20px;

		  span {
		  	font-size: 14px;
		  	color: #b4b4b4;
		  	font-family: 'sfu_universregular';
		  }
        }
    }
    .lg-br {
    	width: 100%;
    	height: 100%;
    	position: absolute;
    	top: 100%;
    	left: 0;
    	background-color: $blue-bg;
    	background-size: contain !important;
    	transition: all 0.3s ease-Out;
    	background-position: center center !important;
    	text-align: center;
    	color: #fff;

    	h4 {
    		padding: 0 10px;
    		padding-bottom: 20px;
    		margin-top: 20px;
    		font-family: 'playfair_displayregular';
    	}
    	p {
    		padding: 0 10px;
    	}
    }

    &:hover {
    	// img {
    	// 	-ms-transform: scale(1.1); /* IE 9 */
		   //  -webkit-transform: scale(1.1); /* Safari */
		   //  transform: scale(1.1);
    	// }
    	// .copy {
    	// 	background: $blue-bg;

    	// 	p {
    	// 		color: #fff;
    	// 	}
    	// }

    	.lg-br {
    		top: 0;
    	}
    }
}

.more-brand {
	display: none;
}

.more-downtown {
	margin: 0 15px 0 15px;
	padding: 50px 0;
	border-top: 1px #cccccc solid;
	position: relative;

	.content {
		position: relative;

		&:hover {
			.btn-w-bg {
			    text-decoration: none;
			    background: #bfbfbf;
			  }
		}
	}

	img {
		width: 100%;
	}

	.copy {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
		text-align: center;
		 -ms-transform: translateY(-50%);
		    -webkit-transform: translateY(-50%);
		    transform: translateY(-50%);

		    h3 {
		    	font-size: 32px;
		    	color: #fff;
		    	padding-bottom: 20px;

		    	span {
		    		display: block;
		    		width: 100%;
		    		font-family: 'sfu_universlight';
		    		font-size: 16px;
		    	}
		    }
	}
}

.more-fb {
	margin: 50px 15px 0 15px;
	padding: 50px 0;
	border-top: 1px #cccccc solid;
	position: relative;

	.content {
		position: relative;

		img {
			width: 100%;
			display: inline-block;
			margin-bottom: 17px;
			transition: all 0.3s ease;
		}

		.left {
			width: 50%;
			padding-right: 20px;
		}
		.right {
			width: 50%;
			padding-left: 20px;
		}

		.copy {
			position: relative;
			padding-right: 210px;
			width: 100%;

			h3 {
				font-size: 24px;
				color: $blue-bg;
				line-height: 30px;
			}
			a {
				position: absolute;
				top: 0;
				right: 0;
			}
		}

		>div {

			&:hover {
				img {
					opacity: 0.88;
				}
			}
		}
	}

}
.tophouse {
	padding-bottom: 50px;
	padding-top: 20px;
}

.mall-wrap {
	padding-top: 50px;
	ul {
		width: 100%;
		display: block;
		padding-top: 40px;

		li {
			display: block;
			width: 100%;
			clear: both;
			overflow: hidden;
			margin-bottom: 50px;
			float: left;
			position: relative;

			.img {
				float: left;
				width: 50%;
				padding-right: 26px;

				img {
					max-width: 100%;
				}
			}

			.copy {
				width: 50%;
				padding-left: 26px;
				position: absolute;
				top: 50%;
				right: 0;
				-ms-transform: translateY(-50%);
		    -webkit-transform: translateY(-50%);
		    transform: translateY(-50%);

				h3 {
					font-size: 24px;
					color: $blue-bg;
					line-height: 30px;
					padding-bottom: 15px;
				}

				p {
					line-height: 24px;
					padding-bottom: 10px;
				}
			}
		}
	}
}

#pHousesDetail .mall-wrap ul li:nth-child(2n) {
	.img {
		float: right;
		padding-right: 0;
		padding-left: 26px;
	}
	.copy {
		padding-right: 26px;
		padding-left: 0;
		right: auto;
		left: 0;
	}
}

.pad-top-none {
	padding-top: 0 !important;
}
.loyalty {
	ul {
		padding-top: 0;
	}
}
.leafPage {
	li:hover {
		.img {
			opacity: 0.88;
		}
		.copy {
			background: $blue-bg;
			color: #fff;
		}
	}
}
.pretext {
 	font-size: 20px;
 	color: #b4b4b4;
 	text-align: center;
 	display: block;
 	width: 50%;
 	position: absolute;
 	right: 0;
 	bottom: 45px;
 	padding-right: 6%; 
 	font-family: 'playfair_displayregular'; 

 	span {
 		font-family: 'playfair_displaybold';
 		text-transform: uppercase;

 	}
}



.all-brand-wrap {
	padding-top: 40px;
	background: url(../images/demo/bg-allbrand.jpg) center 150px  no-repeat;
	background-attachment: fixed; 
	background-size: 100%; 

	.all-b-l {
		display: block;
		position: fixed;
		left: 0;
		bottom: 0;
	}
	.all-b-r {
		display: block;
		position: fixed;
		right: 0;
		bottom: 0;
	}
}
.brand-content {
	padding: 65px 0 55px 0;
	border-bottom: 1px #9d9999 solid;
	opacity: 0;

	&:last-child {
		border-bottom: none;
	}

	h3 {
		color: $blue-bg;
		font-size: 36px;
		text-align: center;
		padding-bottom: 24px;
	}

	ul {
		display: block;
		width: 100%;
		margin: 0 auto;

		li {
			display: block;
			float: left;
			padding-right: 10px;
			padding-bottom: 7px;

			a {
				color: #4c4c4c;
				font-size: 13px;
				text-transform: uppercase;

				&:hover {
					color: $blue-bg;
					text-decoration: none;
				}
			}
		}
	}
}

.colum-5 {
	width: 100%;

	li {
		width: 20%;
	}
}

.colum-3 {
	max-width: 677px;

	li {
		width: 33.3333%;
	}
}



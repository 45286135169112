$blue-bg : #253a71;

/* Generated by Font Squirrel (https://www.fontsquirrel.com) on May 30, 2016 */
@font-face {
  font-family: 'sfu_universbold';
  src: url("../font/sfuuniversbold-webfont.eot");
  src: url("../font/sfuuniversbold-webfont.eot?#iefix") format("embedded-opentype"), url("../font/sfuuniversbold-webfont.woff2") format("woff2"), url("../font/sfuuniversbold-webfont.woff") format("woff"), url("../font/sfuuniversbold-webfont.ttf") format("truetype"), url("../font/sfuuniversbold-webfont.svg#sfu_universbold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sfu_universlight';
  src: url("../font/sfuuniverslight-webfont.eot");
  src: url("../font/sfuuniverslight-webfont.eot?#iefix") format("embedded-opentype"), url("../font/sfuuniverslight-webfont.woff2") format("woff2"), url("../font/sfuuniverslight-webfont.woff") format("woff"), url("../font/sfuuniverslight-webfont.ttf") format("truetype"), url("../font/sfuuniverslight-webfont.svg#sfu_universlight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sfu_universregular';
  src: url("../font/sfuuniversregular-webfont.eot");
  src: url("../font/sfuuniversregular-webfont.eot?#iefix") format("embedded-opentype"), url("../font/sfuuniversregular-webfont.woff2") format("woff2"), url("../font/sfuuniversregular-webfont.woff") format("woff"), url("../font/sfuuniversregular-webfont.ttf") format("truetype"), url("../font/sfuuniversregular-webfont.svg#sfu_universregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: 'playfair_displaybold';
//   src: url("../font/playfairdisplay-bold-webfont.eot");
//   src: url("../font/playfairdisplay-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../font/playfairdisplay-bold-webfont.woff2") format("woff2"), url("../font/playfairdisplay-bold-webfont.woff") format("woff"), url("../font/playfairdisplay-bold-webfont.ttf") format("truetype"), url("../font/playfairdisplay-bold-webfont.svg#playfair_displaybold") format("svg");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'playfair_displayregular';
//   src: url("../font/playfairdisplay-regular-webfont.eot");
//   src: url("../font/playfairdisplay-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../font/playfairdisplay-regular-webfont.woff2") format("woff2"), url("../font/playfairdisplay-regular-webfont.woff") format("woff"), url("../font/playfairdisplay-regular-webfont.ttf") format("truetype"), url("../font/playfairdisplay-regular-webfont.svg#playfair_displayregular") format("svg");
//   font-weight: normal;
//   font-style: normal;
// }




@font-face {
  font-family: 'playfair_displaybold';
  src: url('../font/PlayfairDisplay-Bold.eot?#iefix') format('embedded-opentype'),  
  url('../font/PlayfairDisplay-Bold.woff') format('woff'), 
  url('../font/PlayfairDisplay-Bold.ttf')  format('truetype'), 
  url('../font/PlayfairDisplay-Bold.svg#playfair_displaybold') format('svg');
  font-weight: normal;
  font-style: normal;
}


// @font-face {
//   font-family: 'playfair_displaybold';
//   src: url('../font/PlayfairDisplay-Bold.eot?#iefix') format('embedded-opentype'),  
//   url('../font/PlayfairDisplay-Bold.otf')  format('opentype'),
//        url('../font/PlayfairDisplay-Bold.woff') format('woff'), 
//        url('../font/PlayfairDisplay-Bold.ttf')  format('truetype'), 
//        url('../font/PlayfairDisplay-Bold.svg#playfair_displaybold') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }
@font-face {
  font-family: 'playfair_displayregular';
  src: url('../font/PlayfairDisplay.eot?#iefix') format('embedded-opentype'),  
  url('../font/PlayfairDisplay.woff') format('woff'), 
  url('../font/PlayfairDisplay.ttf')  format('truetype'), 
  url('../font/PlayfairDisplay.svg#playfair_displayregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UVFDidotLTStd-Italic';
  src: url("../font/UVFDidotLTStd-Italic.eot?#iefix") format("embedded-opentype"), url("../font/UVFDidotLTStd-Italic.woff") format("woff"), url("../font/UVFDidotLTStd-Italic.ttf") format("truetype"), url("../font/UVFDidotLTStd-Italic.svg#UVFDidotLTStd-Italic") format("svg");
  font-weight: normal;
  font-style: normal;
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
  font-family: 'playfair_displaybold';
}



html {
  width: 100%;
  min-height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  font-family: 'sfu_universlight';
  position: relative;
  line-height: 1.4;
  color: #4c4c4c;
  transition: all 0.3s;
  font-size: 16px;
}

#main, .page {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

#main {
  padding-top: 120px;
}

.btn-bg {
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 60px;
  padding: 0 35px;
  background: $blue-bg;
  color: #eec2a3;
  letter-spacing: 3px;
  transition: background-color 0.3s ease;
  border: 1px $blue-bg solid;
  font-family: 'sfu_universregular';
  position: relative;
  text-align: center;

  span {
    display: inline-block;
    background: url(../images/ar-link-w.png) right no-repeat;
    background-size: 4px;
    padding-right: 10px;
    width: 100%;
  }
}



.btn-bg:hover {
  background: #fff;
  color: $blue-bg;
  text-decoration: none;
  border: 1px $blue-bg solid;

  span {
    background: url(../images/ar-link.png) right no-repeat;
    background-size: 4px;
  }
}

.btn-bd {
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 50px;
  padding: 0 0;
  color: #000;
  font-family: 'sfu_universregular';
  position: relative;
  padding: 0 30px;
  min-width: 200px;
  height: 50px;
  text-align: center;
  letter-spacing: 1px;
  border: 1px #c3c3c3 solid;

  span {
    display: inline-block;
    background: url(../images/ar-link.png) right center no-repeat;
    background-size: 4px;
    padding-right: 10px;
    width: 100%;
  }
}
.android .btn-bd span {
    background: url(../images/ar-link.png) right 20px no-repeat;
    background-size: 4px;
}

.btn-bd:hover {
  text-decoration: none;
  color: $blue-bg;
  border: 1px $blue-bg solid;

  span {
    background: url(../images/ar-link-hv.png) right no-repeat;
    background-size: 4px;
  }
}

.btn-bd rect {
  -webkit-transition-property: all;
  -webkit-transition-duration: .5s;
  -moz-transition-timing-function: cubic-bezier(100, 50, 21, 6);
  -moz-transition-property: all;
  -moz-transition-duration: .5s;
  -moz-transition-timing-function: cubic-bezier(100, 50, 21, 6);
}

.btn-bd svg,
.btn-bg svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.btn-bd svg rect {
  fill: none;
  stroke: #d0d0d0;
  stroke-width: 1;
  stroke-dasharray: 422, 0;
}

.btn-bd:hover svg rect {
  stroke-width: 5;
  stroke: $blue-bg;
  stroke-dasharray: 50, 313;
  stroke-dashoffset: 82;
}

$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$timing: 0.2s;

.btn-bd {
  appearance: none;
  background-color: transparent;
  // box-shadow: 0 0 0 1px #d0d0d0 inset;
  margin: auto;
  position: relative;
  cursor: pointer;
  
  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 0;
    width: 2px;
    transition: height $timing $timing $easeInOutQuint, width $timing $easeInOutQuint;
  }

  &::before {
    box-shadow: 2px 2px 0 $blue-bg inset;
    bottom: 0;
    left: 0;
  }
  
  &::after {
    box-shadow: -2px -2px 0 $blue-bg inset;
    top: 0;
    right: 0;
  }
  
  &:hover {
    &::before, &::after {
      height: 100%;
      width: 100%;
      transition: height $timing $easeInOutQuint, width $timing $timing $easeInOutQuint;
    }
  }
}

.btn-bg {
  appearance: none;
  // box-shadow: 0 0 0 1px #d0d0d0 inset;
  margin: auto;
  position: relative;
  cursor: pointer;
  
  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 0;
    width: 2px;
    transition: height $timing $timing $easeInOutQuint, width $timing $easeInOutQuint;
  }

  &::before {
    box-shadow: 2px 2px 0 $blue-bg inset;
    bottom: 0;
    left: 0;
  }
  
  &::after {
    box-shadow: -2px -2px 0 $blue-bg inset;
    top: 0;
    right: 0;
  }
  
  &:hover {
    &::before, &::after {
      height: 100%;
      width: 100%;
      transition: height $timing $easeInOutQuint, width $timing $timing $easeInOutQuint;
    }
  }
}

.btn-w-bg {
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 50px;
  padding: 0 0;
  color: #000;
  transition: background-color 0.3s ease;
  font-family: 'sfu_universregular';
  position: relative;
  padding: 0 30px;
  min-width: 200px;
  height: 50px;
  text-align: center;
  letter-spacing: 1px;
  background: #fff; 

  span {
    display: inline-block;
    background: url(../images/ar-link.png) right no-repeat;
    background-size: 4px;
    padding-right: 10px;
    width: 100%;
  }

  &:hover {
    text-decoration: none;
    background: #bfbfbf;
  }
}


.color-w {
  color: #fff !important;
}

.left {
  float: left;
}

.right {
  float: right;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
}

.container:after {
  content: "";
  clear: both;
  display: block;
}

.founder-wrap-home {
  padding: 0 10px;
}

.big-tt {
  font-size: 48px;
  color: $blue-bg;
  text-align: center;
  //padding-top: 44px;
  opacity: 0;
  font-family: 'playfair_displaybold';
  background: url(../images/crossline.png) bottom center no-repeat; 
  padding-bottom: 10px;
  line-height: 60px;
}

.small-tt {
  font-size: 30px;
  color: $blue-bg;
  text-align: center;
  opacity: 0;
  font-family: 'playfair_displaybold';
  padding-top: 44px;
}


.item-news {
  width: 100%;
  text-align: left;

  .img {
    width: 100%;
    overflow: hidden;
    transition: all 0.3s ease;
    background-size: cover !important;

    img {
      max-width: 100%;
      

    }
  }

  .copy {
    padding: 10px 20px;
    background: none;
    transition: background 0.3s ease;
    -webkit-perspective: 1000;
-webkit-backface-visibility: hidden; 
will-change: transform;
opacity: 1; 
  }

  p {
    font-size: 12px;
    font-family: 'sfu_universregular';
    color: #393939;
    padding-bottom: 3px;
    display: inline-block;
    -webkit-perspective: 1000;
-webkit-backface-visibility: hidden; 

    span {
      font-family: 'playfair_displayregular';
    } 
  }
  .tt {
    font-size: 18px;
    color: #000;
    font-family: 'playfair_displayregular';
    display: inline-block;
    line-height: 26px;
    min-height: 54px;
    width: 100%;


    .ar-link {
      display: inline-block;
      width: 6px;
      height: 11px;
      vertical-align: middle;
      background: url(../images/ar-link.png) no-repeat;
      background-size: 6px;
      margin-left: 10px;

      }
  }

  &:hover {

    .img {
     opacity: 0.88;
    }

    .copy {
      background: $blue-bg;
      opacity: 1; 
      -webkit-perspective: 1000;
-webkit-backface-visibility: hidden; 
will-change: transform;


        p {
          color: #fff;
        }
    }

    .tt {
      text-decoration: none;
      color: #fff;
      // font-family: 'playfair_displaybold';
      // font-size: 18px;
      -webkit-perspective: 1000;
-webkit-backface-visibility: hidden; 

      .ar-link {
        background: url(../images/ar-link-w.png) no-repeat;
        background-size: 6px;
      }
    }
  }
}

.browser-ie .item-news .copy {
  transition: none;
}


.btn-scrollTop {
  position: fixed;
  bottom: -100px;
  right: 50px;
  text-align: center;
  font-size: 10px;
  color: #b7b7b7;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.5s ease;
  z-index: 9;
}

.btn-scrollTop span {
  display: inline-block;
  width: 53px;
  height: 53px;
  margin-bottom: 10px;
  position: relative;
}

.btn-scrollTop span:before {
  content: '';
  display: block;
  width: 53px;
  height: 53px;
  border: 1px #b7b7b7 solid;
  transition: all 0.3s ease;
  border-radius: 200px; 
  -ms-transform: rotate(0);
  /* IE 9 */
  -webkit-transform: rotate(0);
  /* Chrome, Safari, Opera */
  transform: rotate(0);
  position: absolute;
  top: 0;
  left: 0;
}

.btn-scrollTop span:after {
  content: '';
  display: block;
  width: 53px;
  height: 53px;
  position: absolute;
  top: -100%;
  left: 0;
  background: url(../images/icon-scrollTop.png) center center no-repeat;
  background-size: 19px;
  transition: all 0.3s ease;
  -ms-transform: rotate(0);
  /* IE 9 */
  -webkit-transform: rotate(0);
  /* Chrome, Safari, Opera */
  transform: rotate(0);
  animation: scrollarrow 2s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-scrollTop:hover span:before {
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

// .btn-scrollTop:hover span:after {
//   height: 60px;
//   top: -6px;
// }

.btn-scrollTop.infooter {
  color: #eec2a3;
}

.btn-scrollTop.infooter span:before {
  border: 1px #eec2a3 solid;
}

.btn-scrollTop.infooter span:after {
  background: url(../images/icon-scrollTop-ft.png) center center no-repeat;
  background-size: 19px;
}

@keyframes scrollarrow {
  0% {
    background-position: center 200%;
  }
  100% {
    background-position: center -100%;
  }
}



.grey-bg {
  background: #e7e7e7;
}

.breadcrumb {
  width: 100%;
  border-bottom: 1px #e2e2e2 solid;
  padding: 17px 0 17px 0;
}
.grey-bg .breadcrumb {
  border-bottom: 1px #c2c2c2 solid;
}

.breadcrumb a {
  font-size: 12px;
  color: #565656;
  display: inline-block;
  background: url(../images/arrow-bread.png) right 6px no-repeat;
  padding-right: 10px;
  margin-right: 5px;
}

.window .breadcrumb a {
  background: url(../images/arrow-bread.png) right 5px no-repeat;
}
.android .breadcrumb a {
  background: url(../images/arrow-bread.png) right center no-repeat;
}

.breadcrumb a:hover {
  color: #253a71;
  text-decoration: none;
}

.breadcrumb span {
  font-size: 12px;
  color: #565656;
}

.img {
  width: 100%;
  overflow: hidden;
}


.banner-page {
  background: #171d2d;
  position: relative;

  div {
    max-width: 100%;
    height: 450px;
    margin: 0 auto;
    position: relative;
    background-size: cover;
    background-position: center;
  }

  h2 {
    display: inline-block;
    width: 100%;
    font-size: 48px;
    line-height: 78px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    color: #2a2a2a;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    //text-shadow: 0 0 91px #ffffff;
  }
}



.banner-top {
  width: 100%;
  background: #fff;
  position: relative;

  .img {
      float: left;
      width: 100%;
      opacity: 1;

      img {
        max-width: 100%;
      }
    }
    .img-full {
      width: 100%;
    }
    .copy {
      width: 50%;
      float: left;
      text-align: center;
      padding-right: 6%;
      position: absolute;
      top: 50%;
      right: 0;
      -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);

      .tt {
        color: $blue-bg;
        font-size: 35px;
        display: inline-block;
        padding-bottom: 15px;
        font-weight: normal;
  font-family: 'playfair_displaybold';

        &:hover {
          color: #284e96;
          text-decoration: none;
        }
      }
      h2 {
        font-size: 48px;
        padding-bottom: 15px;
        margin-bottom: 15px;

        img {
          max-width: 100%;
        }
      }
      p {
        padding-bottom: 30px;
        font-family: 'playfair_displayregular';
        font-size: 20px;
        opacity: 1;
      }
    }

  &:after {
    content: "";
      clear: both;
      display: block;
  }
}



.section-page {
  padding: 50px 0 50px 0;
}


/*-----------------style-searchpage-----------------------*/
.searchgoogle .copy-searchgoogle {
  width: 100%;
}

.searchgoogle .copy-searchgoogle * {
  font-family: 'sfu_universlight';
  font-size: 14px;
  line-height: 1.2;
}

.gs-webResult div.gs-visibleUrl,
.gs-imageResult div.gs-visibleUrl {
  color: #253a71 !important;
}

.gs-webResult.gs-result a.gs-title:link,
.gs-webResult.gs-result a.gs-title:link b,
.gs-imageResult a.gs-title:link, .gs-imageResult a.gs-title:link b {
  color: #253a71 !important;
}

.gs-webResult.gs-result a.gs-title:visited,
.gs-webResult.gs-result a.gs-title:visited b,
.gs-imageResult a.gs-title:visited,
.gs-imageResult a.gs-title:visited b {
  color: #253a71 !important;
}

.cse .gsc-search-button input.gsc-search-button-v2, input.gsc-search-button-v2 {
  height: 100% !important;
  width: auto !important;
  margin-top: 3px !important;
  padding: 4px 20px !important;
}

input.gsc-search-button, input.gsc-search-button:hover, input.gsc-search-button:focus {
  background-color: #253a71 !important;
  background-size: auto auto !important;
}

.gsc-control-cse .gsc-option-menu-container {
  vertical-align: top;
}

.gsc-selected-option {
  line-height: 27px !important;
}

.gsib_a {
  padding: 3px 10px !important;
}

.gsc-table-result, .gsc-thumbnail-inside, .gsc-url-top {
  padding-left: 0 !important;
}

.gsc-results .gsc-cursor-box {
  text-align: center;
  padding: 30px 0 !important;
}

.gsc-results .gsc-cursor-box .gsc-cursor-page {
  display: inline-block !important;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px #c3c3c3 solid;
  font-size: 14px;
  color: #959595 !important;
  position: relative;
  margin-right: 1px !important;
}

.gsc-results .gsc-cursor-box .gsc-cursor-page:hover {
  background: #ebebeb;
  z-index: 2;
  text-decoration: none !important;
}

.gsc-results .gsc-cursor-box .gsc-cursor-current-page {
  border-color: #c3c3c3 !important;
  background-color: #253a71 !important;
  color: #959595 !important;
}


.page404 {
  width: 100%;
  height: 590px;
  background: url(../images/demo/404-bg.jpg) center no-repeat;
  background-size: cover !important;
  text-align: center;
  padding: 80px 10px 0 10px;

  p{
    text-transform: uppercase;
    font-size: 22px;
    color: #e2b190;
    padding-bottom: 70px;
  }
}

.ovl-ss {
  background: #000;
  opacity: 0.3;
  z-index: 10;
  display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}
.success-alert {
  font-family: Arial, sans-serif;
  z-index: 11;
  font-size: 12px;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 800px;
  height: 450px;
  background: url(../images/demo/bg-popup.jpg) center left no-repeat;
  background-size: cover;
  padding: 30px 10px;
  color: #000;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;

  .btn-close {
    background: url(../images/btn-close2.png) no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100%;
    opacity: 1;
  } 

  .copy {
      width: 100%;
      padding: 0 20px;
      position: absolute;
      top: 50%;
      left: 0;
      -ms-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%); 
    } 

    h3 {
      font-family: 'playfair_displaybold';
      display: inline-block;
      width: 100%;  
      padding-bottom: 10px;
      font-size: 36px;
      text-align: center;
      color: $blue-bg;
    }

  p {
    text-align: center;
    font-size: 18px;
    color: #4c4c4c;

  }

  .btn-ct {
    text-align: center;
    padding-top: 30px;
    a {
      // display: inline-block;
      // font-size: 12px;
      // text-transform: uppercase;
      // line-height: 40px;
      // padding: 0 0;
      // color: #fff;
      // font-family: 'sfu_universregular';
      // position: relative;
      // padding: 0 30px;
      // min-width: 200px;
      // height: 40px;
      // text-align: center;
      // letter-spacing: 1px;
      // border: 1px $blue-bg solid;
      // cursor: pointer;
      // background:none;
      // background: $blue-bg;
      // border-radius: 0;
      // margin: 0 8px;
      margin: 0 8px;
      letter-spacing: 1px;
      line-height: 50px;
      height: 50px;
      font-size: 14px;

      &:hover {
        text-decoration: none;
        color: $blue-bg;
        background: none;
        border: 1px $blue-bg solid;
      }
    }
    .btn-bd {
        border: 1px $blue-bg solid;
        color: $blue-bg;
      }
  }
}

html.noscroll {
    position: fixed; 
    overflow-y: scroll;
    width: 100%;
}


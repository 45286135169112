.timeline-wrap {
  width: 100%;
  margin: 0 auto;
  height: 4662px;
  position: relative;
      background: url(../images/line-timeline.png) center 188px no-repeat;
    background-size: 1px 4064px;
}

.linetime-item {
  width: 50%;
  padding-right: 100px;
  position: absolute;
  left: 0;
}

// .marker{
//     display: none;
//     position: absolute;
//     top: 50%;
//     right: -16px;
//     margin-top: -30px;
//   }


// .linetime-item:nth-child(2n) {
//   left: auto;
//   right: 0;
// }

// // .linetime-item:nth-child(2n) .copy {
// //   -webkit-transform: translate3d(100px, 0, 0);
// //   transform: translate3d(100px, 0, 0);
// // }


// .linetime-item .copy {
//   width: 100%;
//   // opacity: 0;
//   // -webkit-transform: translate3d(-100px, 0, 0);
//   // transform: translate3d(-100px, 0, 0);
//   // transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
// }

// .linetime-item .copy .img {
//   margin-bottom: 39px;
// }

// .linetime-item .copy .img img {
//   width: 100%;
// }

// .linetime-item .copy h3 {
//   color: #253a71;
//   font-size: 60px;
//   display: inline-block;
//   width: 39%;
//   vertical-align: top;
//   line-height: 19px;
// }

// .linetime-item .copy p {
//   display: inline-block;
//   width: 60%;
//   font-size: 14px;
//   font-family: 'sfu_universlight';
//   line-height: 25px;
//   background: url(../images/cross-line3.png) top left no-repeat;
//   padding-top: 17px;
//   vertical-align: top;
//   color: #4c4c4c;
// }

// .linetime-item.animate-scroll .copy {
//   opacity: 1;
//   -webkit-transform: translate3d(0, 0, 0);
//   transform: translate3d(0, 0, 0);
// }


.linetime-item .marker {
  display: block;
  position: absolute;
  top: 50%;
  right: -16px;
  // margin-top: -90px;
}

.linetime-item:nth-child(2n) {
  padding-left: 100px;
  padding-right: 0;
  left: auto;
  right: 0;
}

.linetime-item:nth-child(2n) .copy {
  -webkit-transform: translate3d(100px, 0, 0);
  transform: translate3d(100px, 0, 0);
}

.linetime-item:nth-child(2n) .marker {
  display: block;
  position: absolute;
  top: 50%;
  left: -15px;
  right: auto;
  // margin-top: -90px;
}

.linetime-item .copy {
  width: 100%;
  opacity: 0;
  -webkit-transform: translate3d(-100px, 0, 0);
  transform: translate3d(-100px, 0, 0);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.linetime-item .copy .img {
  margin-bottom: 39px;
}

.linetime-item .copy .img img {
  width: 100%;
}

.linetime-item .copy h3 {
  color: #253a71;
  font-size: 60px;
  display: inline-block;
  width: 39%;
  vertical-align: top;
  line-height: 19px;
}

.linetime-item .copy p {
  display: inline-block;
  width: 60%;
  font-size: 14px;
  font-family: 'sfu_universlight';
  line-height: 25px;
  background: url(../images/cross-line3.png) top left no-repeat;
  padding-top: 17px;
  vertical-align: top;
  color: #4c4c4c;
}

.linetime-item.animate-scroll .copy {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}




.y1985 {
  top: 56px;
}
.y1986 {
  top: 212px;
}
.y1993 {
  top: 620px;
}
.y2000 {
  top: 876px;
}
.y2005 {
  top: 1205px;
}
.y2009 {
  top: 1538px;
}
.y2010 {
  top: 1895px;
}
.y2011 {
  top: 2297px;
}
.y2012 {
  top: 2694px;
}
.y2013 {
  top: 3036px;
}
.y2015 {
  top: 3420px;
}
.y2016 {
  top: 3744px;
}
.y2017 {
  top: 4111px;
}